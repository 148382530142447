import { backendUri } from "@/config/config";
import Cookies from "js-cookie";

type Func = (...args: any[]) => void;

export function debounce(func: Func, wait: number, immediate: boolean) {
  let timeout: NodeJS.Timeout | null;

  return function (this: any, ...args: any[]) {
    const context = this;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export default async function RoleCheck() {
  let roleResult = {
    isLoggedIn: false,
    isAdmin: false,
    isUser: false,
    isSubAdmin: false,
    SubAdminid: "",
  };
  try {
    let tokenResult = Cookies.get("cat");
    if (tokenResult && tokenResult.length) {
      roleResult.isLoggedIn = true;
      let userDetails = await getUserDetails(tokenResult);
      let { role, _id } = userDetails;
      roleResult.isSubAdmin = role === "admin" ? true : false;
      roleResult.SubAdminid = role === "admin" ? _id : "";
      roleResult.isAdmin = role === "superadmin" ? true : false;
      roleResult.isUser = role === "user" ? true : false;
    }
    return roleResult;
  } catch (e) {
    // console.log("Error : ", e);
    return roleResult;
  } finally {
    return roleResult;
  }
}

const getUserDetails = (tokenResult: string) => {
  return fetch(`${backendUri}/api/v1/user/profile`, {
    method: "Get",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${tokenResult}`,
      "Content-Type": "application/json",
    },
  })
    .then(async (result) => {
      if (result.status == 200) {
        return await result.json().then((data) => {
          return data.data;
        });
      } else {
        throw new Error();
      }
    })
    .catch((e) => {
      // console.log("Error:  ", e);
      throw e;
    });
};

export const StateList2 = [
  { label: "Any Location", value: "Any Location" },
  { label: "Outside India", value: "Outside India" },
  { label: "Remote", value: "Remote" },
  { label: "Mumbai", value: "Mumbai" },
  { label: "Pune", value: "Pune" },
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Odisha", value: "Odisha" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
];

// export const StateList = [
//     // { label: "Any Location", value: "Any Location" },
//     { label: "Outside India", value: "Outside India" },
//     { label: "Remote", value: "Remote" },
//     { label: "Mumbai; Maharashtra", value: "Mumbai; Maharashtra" },
//     { label: "Delhi; Delhi", value: "Delhi; Delhi" },
//     { label: "Bangalore; Karnataka", value: "Bangalore; Karnataka" },
//     { label: "Hyderabad; Telangana", value: "Hyderabad; Telangana" },
//     { label: "Ahmedabad; Gujarat", value: "Ahmedabad; Gujarat" },
//     { label: "Chennai; Tamil Nadu", value: "Chennai; Tamil Nadu" },
//     { label: "Kolkata; West Bengal", value: "Kolkata; West Bengal" },
//     { label: "Surat; Gujarat", value: "Surat; Gujarat" },
//     { label: "Pune; Maharashtra", value: "Pune; Maharashtra" },
//     { label: "Jaipur; Rajasthan", value: "Jaipur; Rajasthan" },
//     { label: "Nagpur; Maharashtra", value: "Nagpur; Maharashtra" },
//     { label: "Thane; Maharashtra", value: "Thane; Maharashtra" },
//     { label: "Indore; Madhya Pradesh", value: "Indore; Madhya Pradesh" },
//     { label: "Agra; Uttar Pradesh", value: "Agra; Uttar Pradesh" },
//     { label: "Rajkot; Gujarat", value: "Rajkot; Gujarat" },
//     { label: "Dhanbad; Jharkhand", value: "Dhanbad; Jharkhand" },
//     { label: "Amritsar; Punjab", value: "Amritsar; Punjab" },
//     { label: "Gurgaon; Haryana", value: "Gurgaon; Haryana" },
//     { label: "Salem; Tamil Nadu", value: "Salem; Tamil Nadu" },
//     { label: "Kota; Rajasthan", value: "Kota; Rajasthan" },
//     { label: "Noida; Uttar Pradesh", value: "Noida; Uttar Pradesh" },
//     { label: "Bhagalpur; Bihar", value: "Bhagalpur; Bihar" },
//     { label: "Kollam; Kerala", value: "Kollam; Kerala" },
//     { label: "Bihar Sharif; Bihar", value: "Bihar Sharif; Bihar" },
//     { label: "Bharatpur; Rajasthan", value: "Bharatpur; Rajasthan" },
//     { label: "New Delhi; Delhi", value: "New Delhi; Delhi" },
//     { label: "Gandhidham; Gujarat", value: "Gandhidham; Gujarat" },
//     { label: "Nadiad; Gujarat", value: "Nadiad; Gujarat" },
//     { label: "Kavali; Andhra Pradesh", value: "Kavali; Andhra Pradesh" },
//     { label: "Buxar; Bihar", value: "Buxar; Bihar" },
//     { label: "Jehanabad; Bihar", value: "Jehanabad; Bihar" },
// ]

// export const StateList = [
//   { label: "Any Location", value: "Any Location" },
//   { label: "Outside India", value: "Outside India" },
//   { label: "Remote", value: "Remote" },
//   { label: "Mumbai; Maharashtra; ", value: "Mumbai; Maharashtra" },
//   { label: "Delhi; Delhi; ", value: "Delhi; Delhi" },
//   { label: "Bangalore; Karnataka; ", value: "Bangalore; Karnataka" },
//   { label: "Hyderabad; Telangana; ", value: "Hyderabad; Telangana" },
//   { label: "Ahmedabad; Gujarat; ", value: "Ahmedabad; Gujarat" },
//   { label: "Chennai; Tamil Nadu; ", value: "Chennai; Tamil Nadu" },
//   { label: "Kolkata; West Bengal; ", value: "Kolkata; West Bengal" },
//   { label: "Surat; Gujarat; ", value: "Surat; Gujarat" },
//   { label: "Pune; Maharashtra; ", value: "Pune; Maharashtra" },
//   { label: "Jaipur; Rajasthan; ", value: "Jaipur; Rajasthan" },
//   { label: "Lucknow; Uttar Pradesh; ", value: "Lucknow; Uttar Pradesh" },
//   { label: "Kanpur; Uttar Pradesh; ", value: "Kanpur; Uttar Pradesh" },
//   { label: "Nagpur; Maharashtra; ", value: "Nagpur; Maharashtra" },
//   { label: "Indore; Madhya Pradesh; ", value: "Indore; Madhya Pradesh" },
//   { label: "Thane; Maharashtra; ", value: "Thane; Maharashtra" },
//   { label: "Bhopal; Madhya Pradesh; ", value: "Bhopal; Madhya Pradesh" },
//   {
//     label: "Visakhapatnam; Andhra Pradesh; ",
//     value: "Visakhapatnam; Andhra Pradesh",
//   },
//   {
//     label: "Pimpri-Chinchwad; Maharashtra; ",
//     value: "Pimpri-Chinchwad; Maharashtra",
//   },
//   { label: "Patna; Bihar; ", value: "Patna; Bihar" },
//   { label: "Vadodara; Gujarat; ", value: "Vadodara; Gujarat" },
//   { label: "Ghaziabad; Uttar Pradesh; ", value: "Ghaziabad; Uttar Pradesh" },
//   { label: "Ludhiana; Punjab; ", value: "Ludhiana; Punjab" },
//   { label: "Agra; Uttar Pradesh; ", value: "Agra; Uttar Pradesh" },
//   { label: "Nashik; Maharashtra; ", value: "Nashik; Maharashtra" },
//   { label: "Faridabad; Haryana; ", value: "Faridabad; Haryana" },
//   { label: "Meerut; Uttar Pradesh; ", value: "Meerut; Uttar Pradesh" },
//   { label: "Rajkot; Gujarat; ", value: "Rajkot; Gujarat" },
//   {
//     label: "Kalyan-Dombivali; Maharashtra; ",
//     value: "Kalyan-Dombivali; Maharashtra",
//   },
//   { label: "Vasai-Virar; Maharashtra; ", value: "Vasai-Virar; Maharashtra" },
//   { label: "Varanasi; Uttar Pradesh; ", value: "Varanasi; Uttar Pradesh" },
//   {
//     label: "Srinagar; Jammu and Kashmir; ",
//     value: "Srinagar; Jammu and Kashmir",
//   },
//   { label: "Aurangabad; Maharashtra; ", value: "Aurangabad; Maharashtra" },
//   { label: "Dhanbad; Jharkhand; ", value: "Dhanbad; Jharkhand" },
//   { label: "Amritsar; Punjab; ", value: "Amritsar; Punjab" },
//   { label: "Navi Mumbai; Maharashtra; ", value: "Navi Mumbai; Maharashtra" },
//   {
//     label: "Prayagraj/Allahabad; Uttar Pradesh; ",
//     value: "Prayagraj/Allahabad; Uttar Pradesh",
//   },
//   { label: "Howrah; West Bengal; ", value: "Howrah; West Bengal" },
//   { label: "Ranchi; Jharkhand; ", value: "Ranchi; Jharkhand" },
//   { label: "Gwalior; Madhya Pradesh; ", value: "Gwalior; Madhya Pradesh" },
//   { label: "Jabalpur; Madhya Pradesh; ", value: "Jabalpur; Madhya Pradesh" },
//   { label: "Coimbatore; Tamil Nadu; ", value: "Coimbatore; Tamil Nadu" },
//   {
//     label: "Vijayawada; Andhra Pradesh; ",
//     value: "Vijayawada; Andhra Pradesh",
//   },
//   { label: "Jodhpur; Rajasthan; ", value: "Jodhpur; Rajasthan" },
//   { label: "Madurai; Tamil Nadu; ", value: "Madurai; Tamil Nadu" },
//   { label: "Raipur; Chhattisgarh; ", value: "Raipur; Chhattisgarh" },
//   { label: "Kota; Rajasthan; ", value: "Kota; Rajasthan" },
//   { label: "Chandigarh; Chandigarh; ", value: "Chandigarh; Chandigarh" },
//   { label: "Guwahati; Assam; ", value: "Guwahati; Assam" },
//   { label: "Solapur; Maharashtra; ", value: "Solapur; Maharashtra" },
//   { label: "Hubli-Dharwad; Karnataka; ", value: "Hubli-Dharwad; Karnataka" },
//   { label: "Bareilly; Uttar Pradesh; ", value: "Bareilly; Uttar Pradesh" },
//   { label: "Mysore; Karnataka; ", value: "Mysore; Karnataka" },
//   { label: "Moradabad; Uttar Pradesh; ", value: "Moradabad; Uttar Pradesh" },
//   { label: "Gurgaon; Haryana; ", value: "Gurgaon; Haryana" },
//   { label: "Aligarh; Uttar Pradesh; ", value: "Aligarh; Uttar Pradesh" },
//   { label: "Jalandhar; Punjab; ", value: "Jalandhar; Punjab" },
//   {
//     label: "Tiruchirappalli; Tamil Nadu; ",
//     value: "Tiruchirappalli; Tamil Nadu",
//   },
//   { label: "Bhubaneswar; Odisha; ", value: "Bhubaneswar; Odisha" },
//   { label: "Salem; Tamil Nadu; ", value: "Salem; Tamil Nadu" },
//   {
//     label: "Mira-Bhayandar; Maharashtra; ",
//     value: "Mira-Bhayandar; Maharashtra",
//   },
//   {
//     label: "Thiruvananthapuram; Kerala; ",
//     value: "Thiruvananthapuram; Kerala",
//   },
//   { label: "Bhiwandi; Maharashtra; ", value: "Bhiwandi; Maharashtra" },
//   { label: "Saharanpur; Uttar Pradesh; ", value: "Saharanpur; Uttar Pradesh" },
//   { label: "Gorakhpur; Uttar Pradesh; ", value: "Gorakhpur; Uttar Pradesh" },
//   { label: "Guntur; Andhra Pradesh; ", value: "Guntur; Andhra Pradesh" },
//   { label: "Amravati; Maharashtra; ", value: "Amravati; Maharashtra" },
//   { label: "Bikaner; Rajasthan; ", value: "Bikaner; Rajasthan" },
//   { label: "Noida; Uttar Pradesh; ", value: "Noida; Uttar Pradesh" },
//   { label: "Jamshedpur; Jharkhand; ", value: "Jamshedpur; Jharkhand" },
//   { label: "Bhilai; Chhattisgarh; ", value: "Bhilai; Chhattisgarh" },
//   { label: "Warangal; Telangana; ", value: "Warangal; Telangana" },
//   { label: "Cuttack; Odisha; ", value: "Cuttack; Odisha" },
//   { label: "Firozabad; Uttar Pradesh; ", value: "Firozabad; Uttar Pradesh" },
//   { label: "Kochi; Kerala; ", value: "Kochi; Kerala" },
//   { label: "Bhavnagar; Gujarat; ", value: "Bhavnagar; Gujarat" },
//   { label: "Dehradun; Uttarakhand; ", value: "Dehradun; Uttarakhand" },
//   { label: "Durgapur; West Bengal; ", value: "Durgapur; West Bengal" },
//   { label: "Asansol; West Bengal; ", value: "Asansol; West Bengal" },
//   { label: "Nanded; Maharashtra; ", value: "Nanded; Maharashtra" },
//   { label: "Kolhapur; Maharashtra; ", value: "Kolhapur; Maharashtra" },
//   { label: "Ajmer; Rajasthan; ", value: "Ajmer; Rajasthan" },
//   { label: "Gulbarga; Karnataka; ", value: "Gulbarga; Karnataka" },
//   { label: "Loni; Uttar Pradesh; ", value: "Loni; Uttar Pradesh" },
//   { label: "Ujjain; Madhya Pradesh; ", value: "Ujjain; Madhya Pradesh" },
//   { label: "Siliguri; West Bengal; ", value: "Siliguri; West Bengal" },
//   { label: "Ulhasnagar; Maharashtra; ", value: "Ulhasnagar; Maharashtra" },
//   { label: "Jhansi; Uttar Pradesh; ", value: "Jhansi; Uttar Pradesh" },
//   {
//     label: "Sangli-Miraj & Kupwad; Maharashtra; ",
//     value: "Sangli-Miraj & Kupwad; Maharashtra",
//   },
//   { label: "Jammu; Jammu and Kashmir; ", value: "Jammu; Jammu and Kashmir" },
//   { label: "Nellore; Andhra Pradesh; ", value: "Nellore; Andhra Pradesh" },
//   { label: "Mangalore; Karnataka; ", value: "Mangalore; Karnataka" },
//   { label: "Belgaum; Karnataka; ", value: "Belgaum; Karnataka" },
//   { label: "Jamnagar; Gujarat; ", value: "Jamnagar; Gujarat" },
//   { label: "Tirunelveli; Tamil Nadu; ", value: "Tirunelveli; Tamil Nadu" },
//   { label: "Malegaon; Maharashtra; ", value: "Malegaon; Maharashtra" },
//   { label: "Gaya; Bihar; ", value: "Gaya; Bihar" },
//   { label: "Ambattur; Tamil Nadu; ", value: "Ambattur; Tamil Nadu" },
//   { label: "Jalgaon; Maharashtra; ", value: "Jalgaon; Maharashtra" },
//   { label: "Udaipur; Rajasthan; ", value: "Udaipur; Rajasthan" },
//   { label: "Maheshtala; West Bengal; ", value: "Maheshtala; West Bengal" },
//   { label: "Tiruppur; Tamil Nadu; ", value: "Tiruppur; Tamil Nadu" },
//   { label: "Davanagere; Karnataka; ", value: "Davanagere; Karnataka" },
//   { label: "Kozhikode; Kerala; ", value: "Kozhikode; Kerala" },
//   { label: "Kurnool; Andhra Pradesh; ", value: "Kurnool; Andhra Pradesh" },
//   { label: "Akola; Maharashtra; ", value: "Akola; Maharashtra" },
//   {
//     label: "Rajpur Sonarpur; West Bengal; ",
//     value: "Rajpur Sonarpur; West Bengal",
//   },
//   { label: "Bokaro; Jharkhand; ", value: "Bokaro; Jharkhand" },
//   { label: "Bellary; Karnataka; ", value: "Bellary; Karnataka" },
//   { label: "Patiala; Punjab; ", value: "Patiala; Punjab" },
//   { label: "South Dumdum; West Bengal; ", value: "South Dumdum; West Bengal" },
//   { label: "Gopalpur; West Bengal; ", value: "Gopalpur; West Bengal" },
//   { label: "Bhagalpur; Bihar; ", value: "Bhagalpur; Bihar" },
//   { label: "Agartala; Tripura; ", value: "Agartala; Tripura" },
//   {
//     label: "Muzaffarnagar; Uttar Pradesh; ",
//     value: "Muzaffarnagar; Uttar Pradesh",
//   },
//   { label: "Bhatpara; West Bengal; ", value: "Bhatpara; West Bengal" },
//   { label: "Latur; Maharashtra; ", value: "Latur; Maharashtra" },
//   { label: "Panihati; West Bengal; ", value: "Panihati; West Bengal" },
//   { label: "Dhule; Maharashtra; ", value: "Dhule; Maharashtra" },
//   { label: "Rohtak; Haryana; ", value: "Rohtak; Haryana" },
//   { label: "Korba; Chhattisgarh; ", value: "Korba; Chhattisgarh" },
//   { label: "Bhilwara; Rajasthan; ", value: "Bhilwara; Rajasthan" },
//   { label: "Berhampur; Odisha; ", value: "Berhampur; Odisha" },
//   { label: "Muzaffarpur; Bihar; ", value: "Muzaffarpur; Bihar" },
//   { label: "Ahmednagar; Maharashtra; ", value: "Ahmednagar; Maharashtra" },
//   { label: "Mathura; Uttar Pradesh; ", value: "Mathura; Uttar Pradesh" },
//   { label: "Kollam; Kerala; ", value: "Kollam; Kerala" },
//   { label: "Avadi; Tamil Nadu; ", value: "Avadi; Tamil Nadu" },
//   { label: "Kadapa; Andhra Pradesh; ", value: "Kadapa; Andhra Pradesh" },
//   {
//     label: "Rajahmundry; Andhra Pradesh; ",
//     value: "Rajahmundry; Andhra Pradesh",
//   },
//   { label: "Bilaspur; Chhattisgarh; ", value: "Bilaspur; Chhattisgarh" },
//   { label: "Kamarhati; West Bengal; ", value: "Kamarhati; West Bengal" },
//   {
//     label: "Shahjahanpur; Uttar Pradesh; ",
//     value: "Shahjahanpur; Uttar Pradesh",
//   },
//   { label: "Bijapur; Karnataka; ", value: "Bijapur; Karnataka" },
//   { label: "Rampur; Uttar Pradesh; ", value: "Rampur; Uttar Pradesh" },
//   { label: "Shivamogga; Karnataka; ", value: "Shivamogga; Karnataka" },
//   { label: "Chandrapur; Maharashtra; ", value: "Chandrapur; Maharashtra" },
//   { label: "Junagadh; Gujarat; ", value: "Junagadh; Gujarat" },
//   { label: "Thrissur; Kerala; ", value: "Thrissur; Kerala" },
//   { label: "Alwar; Rajasthan; ", value: "Alwar; Rajasthan" },
//   { label: "Bardhaman; West Bengal; ", value: "Bardhaman; West Bengal" },
//   { label: "Kulti; West Bengal; ", value: "Kulti; West Bengal" },
//   { label: "Kakinada; Andhra Pradesh; ", value: "Kakinada; Andhra Pradesh" },
//   { label: "Nizamabad; Telangana; ", value: "Nizamabad; Telangana" },
//   { label: "Parbhani; Maharashtra; ", value: "Parbhani; Maharashtra" },
//   { label: "Tumkur; Karnataka; ", value: "Tumkur; Karnataka" },
//   { label: "Hisar; Haryana; ", value: "Hisar; Haryana" },
//   { label: "Ozhukarai; Puducherry; ", value: "Ozhukarai; Puducherry" },
//   { label: "Bihar Sharif; Bihar; ", value: "Bihar Sharif; Bihar" },
//   { label: "Darbhanga; Bihar; ", value: "Darbhanga; Bihar" },
//   { label: "Panipat; Haryana; ", value: "Panipat; Haryana" },
//   { label: "Aizawl; Mizoram; ", value: "Aizawl; Mizoram" },
//   { label: "Bally; West Bengal; ", value: "Bally; West Bengal" },
//   { label: "Dewas; Madhya Pradesh; ", value: "Dewas; Madhya Pradesh" },
//   { label: "Tirupati; Andhra Pradesh; ", value: "Tirupati; Andhra Pradesh" },
//   { label: "Ichalkaranji; Maharashtra; ", value: "Ichalkaranji; Maharashtra" },
//   { label: "Karnal; Haryana; ", value: "Karnal; Haryana" },
//   { label: "Bathinda; Punjab; ", value: "Bathinda; Punjab" },
//   { label: "Jalna; Maharashtra; ", value: "Jalna; Maharashtra" },
//   {
//     label: "Kirari Suleman Nagar; Delhi; ",
//     value: "Kirari Suleman Nagar; Delhi",
//   },
//   { label: "Purnia; Bihar; ", value: "Purnia; Bihar" },
//   { label: "Satna; Madhya Pradesh; ", value: "Satna; Madhya Pradesh" },
//   { label: "Mau; Uttar Pradesh; ", value: "Mau; Uttar Pradesh" },
//   { label: "Barasat; West Bengal; ", value: "Barasat; West Bengal" },
//   { label: "Sonipat; Haryana; ", value: "Sonipat; Haryana" },
//   {
//     label: "Farrukhabad; Uttar Pradesh; ",
//     value: "Farrukhabad; Uttar Pradesh",
//   },
//   { label: "Sagar; Madhya Pradesh; ", value: "Sagar; Madhya Pradesh" },
//   { label: "Rourkela; Odisha; ", value: "Rourkela; Odisha" },
//   { label: "Durg; Chhattisgarh; ", value: "Durg; Chhattisgarh" },
//   { label: "Imphal; Manipur; ", value: "Imphal; Manipur" },
//   { label: "Ratlam; Madhya Pradesh; ", value: "Ratlam; Madhya Pradesh" },
//   { label: "Hapur; Uttar Pradesh; ", value: "Hapur; Uttar Pradesh" },
//   { label: "Arrah; Bihar; ", value: "Arrah; Bihar" },
//   { label: "Karimnagar; Telangana; ", value: "Karimnagar; Telangana" },
//   { label: "Anantapur; Andhra Pradesh; ", value: "Anantapur; Andhra Pradesh" },
//   { label: "NDMC; Delhi; ", value: "NDMC; Delhi" },
//   { label: "Etawah; Uttar Pradesh; ", value: "Etawah; Uttar Pradesh" },
//   { label: "Ambernath; Maharashtra; ", value: "Ambernath; Maharashtra" },
//   { label: "Bharatpur; Rajasthan; ", value: "Bharatpur; Rajasthan" },
//   { label: "Begusarai; Bihar; ", value: "Begusarai; Bihar" },
//   { label: "Tiruvottiyur; Tamil Nadu; ", value: "Tiruvottiyur; Tamil Nadu" },
//   { label: "North Dumdum; West Bengal; ", value: "North Dumdum; West Bengal" },
//   { label: "Gandhidham; Gujarat; ", value: "Gandhidham; Gujarat" },
//   { label: "Baranagar; West Bengal; ", value: "Baranagar; West Bengal" },
//   { label: "Puducherry; Puducherry; ", value: "Puducherry; Puducherry" },
//   { label: "Thoothukudi; Tamil Nadu; ", value: "Thoothukudi; Tamil Nadu" },
//   { label: "Sikar; Rajasthan; ", value: "Sikar; Rajasthan" },
//   { label: "Rewa; Madhya Pradesh; ", value: "Rewa; Madhya Pradesh" },
//   { label: "Mirzapur; Uttar Pradesh; ", value: "Mirzapur; Uttar Pradesh" },
//   { label: "Raichur; Karnataka; ", value: "Raichur; Karnataka" },
//   { label: "Pali; Rajasthan; ", value: "Pali; Rajasthan" },
//   { label: "Ramagundam; Telangana; ", value: "Ramagundam; Telangana" },
//   { label: "Haridwar; Uttarakhand; ", value: "Haridwar; Uttarakhand" },
//   {
//     label: "Vizianagaram; Andhra Pradesh; ",
//     value: "Vizianagaram; Andhra Pradesh",
//   },
//   { label: "Katihar; Bihar; ", value: "Katihar; Bihar" },
//   { label: "Nagercoil; Tamil Nadu; ", value: "Nagercoil; Tamil Nadu" },
//   { label: "Ganganagar; Rajasthan; ", value: "Ganganagar; Rajasthan" },
//   { label: "Karawal Nagar; Delhi; ", value: "Karawal Nagar; Delhi" },
//   { label: "Mango; Jharkhand; ", value: "Mango; Jharkhand" },
//   { label: "Thanjavur; Tamil Nadu; ", value: "Thanjavur; Tamil Nadu" },
//   {
//     label: "Bulandshahr; Uttar Pradesh; ",
//     value: "Bulandshahr; Uttar Pradesh",
//   },
//   { label: "Uluberia; West Bengal; ", value: "Uluberia; West Bengal" },
//   { label: "Murwara; Madhya Pradesh; ", value: "Murwara; Madhya Pradesh" },
//   { label: "Sambhal; Uttar Pradesh; ", value: "Sambhal; Uttar Pradesh" },
//   { label: "Singrauli; Madhya Pradesh; ", value: "Singrauli; Madhya Pradesh" },
//   { label: "Nadiad; Gujarat; ", value: "Nadiad; Gujarat" },
//   { label: "Secunderabad; Telangana; ", value: "Secunderabad; Telangana" },
//   { label: "Naihati; West Bengal; ", value: "Naihati; West Bengal" },
//   { label: "Yamunanagar; Haryana; ", value: "Yamunanagar; Haryana" },
//   { label: "Bidhan Nagar; West Bengal; ", value: "Bidhan Nagar; West Bengal" },
//   { label: "Pallavaram; Tamil Nadu; ", value: "Pallavaram; Tamil Nadu" },
//   { label: "Bidar; Karnataka; ", value: "Bidar; Karnataka" },
//   { label: "Munger; Bihar; ", value: "Munger; Bihar" },
//   { label: "Panchkula; Haryana; ", value: "Panchkula; Haryana" },
//   { label: "Burhanpur; Madhya Pradesh; ", value: "Burhanpur; Madhya Pradesh" },
//   {
//     label: "Raurkela Industrial Township; Odisha; ",
//     value: "Raurkela Industrial Township; Odisha",
//   },
//   { label: "Kharagpur; West Bengal; ", value: "Kharagpur; West Bengal" },
//   { label: "Dindigul; Tamil Nadu; ", value: "Dindigul; Tamil Nadu" },
//   { label: "Gandhinagar; Gujarat; ", value: "Gandhinagar; Gujarat" },
//   { label: "Hospet; Karnataka; ", value: "Hospet; Karnataka" },
//   { label: "Nangloi Jat; Delhi; ", value: "Nangloi Jat; Delhi" },
//   {
//     label: "English Bazar; West Bengal; ",
//     value: "English Bazar; West Bengal",
//   },
//   { label: "Ongole; Andhra Pradesh; ", value: "Ongole; Andhra Pradesh" },
//   { label: "Eluru; Andhra Pradesh; ", value: "Eluru; Andhra Pradesh" },
//   { label: "Deoghar; Jharkhand; ", value: "Deoghar; Jharkhand" },
//   { label: "Chapra; Bihar; ", value: "Chapra; Bihar" },
//   { label: "Haldia; West Bengal; ", value: "Haldia; West Bengal" },
//   { label: "Khandwa; Madhya Pradesh; ", value: "Khandwa; Madhya Pradesh" },
//   { label: "Puri Town; Odisha; ", value: "Puri Town; Odisha" },
//   { label: "Nandyal; Andhra Pradesh; ", value: "Nandyal; Andhra Pradesh" },
//   { label: "Morena; Madhya Pradesh; ", value: "Morena; Madhya Pradesh" },
//   { label: "Amroha; Uttar Pradesh; ", value: "Amroha; Uttar Pradesh" },
//   { label: "Anand; Gujarat; ", value: "Anand; Gujarat" },
//   { label: "Bhind; Madhya Pradesh; ", value: "Bhind; Madhya Pradesh" },
//   {
//     label: "Bhalswa Jahangir Pur; Delhi; ",
//     value: "Bhalswa Jahangir Pur; Delhi",
//   },
//   { label: "Madhyamgram; West Bengal; ", value: "Madhyamgram; West Bengal" },
//   { label: "Bhiwani; Haryana; ", value: "Bhiwani; Haryana" },
//   {
//     label: "Navi Mumbai Panvel Raigad; Maharashtra; ",
//     value: "Navi Mumbai Panvel Raigad; Maharashtra",
//   },
//   { label: "Baharampur; West Bengal; ", value: "Baharampur; West Bengal" },
//   { label: "Ambala; Haryana; ", value: "Ambala; Haryana" },
//   { label: "Morvi; Gujarat; ", value: "Morvi; Gujarat" },
//   { label: "Fatehpur; Uttar Pradesh; ", value: "Fatehpur; Uttar Pradesh" },
//   { label: "Rae Bareli; Uttar Pradesh; ", value: "Rae Bareli; Uttar Pradesh" },
//   { label: "Khora; Uttar Pradesh; ", value: "Khora; Uttar Pradesh" },
//   { label: "Bhusawal; Maharashtra; ", value: "Bhusawal; Maharashtra" },
//   { label: "Orai; Uttar Pradesh; ", value: "Orai; Uttar Pradesh" },
//   { label: "Bahraich; Uttar Pradesh; ", value: "Bahraich; Uttar Pradesh" },
//   { label: "Vellore; Tamil Nadu; ", value: "Vellore; Tamil Nadu" },
//   { label: "Mahesana; Gujarat; ", value: "Mahesana; Gujarat" },
//   { label: "Khammam; Telangana; ", value: "Khammam; Telangana" },
//   { label: "Sambalpur; Odisha; ", value: "Sambalpur; Odisha" },
//   { label: "Raiganj; West Bengal; ", value: "Raiganj; West Bengal" },
//   { label: "Sirsa; Haryana; ", value: "Sirsa; Haryana" },
//   { label: "Dinapur Nizamat; Bihar; ", value: "Dinapur Nizamat; Bihar" },
//   { label: "Serampore; West Bengal; ", value: "Serampore; West Bengal" },
//   { label: "Sultan Pur Majra; Delhi; ", value: "Sultan Pur Majra; Delhi" },
//   { label: "Guna; Madhya Pradesh; ", value: "Guna; Madhya Pradesh" },
//   { label: "Jaunpur; Uttar Pradesh; ", value: "Jaunpur; Uttar Pradesh" },
//   { label: "Panvel; Maharashtra; ", value: "Panvel; Maharashtra" },
//   { label: "Shivpuri; Madhya Pradesh; ", value: "Shivpuri; Madhya Pradesh" },
//   {
//     label: "Surendranagar Dudhrej; Gujarat; ",
//     value: "Surendranagar Dudhrej; Gujarat",
//   },
//   { label: "Unnao; Uttar Pradesh; ", value: "Unnao; Uttar Pradesh" },
//   {
//     label: "Hugli and Chinsurah; West Bengal; ",
//     value: "Hugli and Chinsurah; West Bengal",
//   },
//   { label: "Sitapur; Uttar Pradesh; ", value: "Sitapur; Uttar Pradesh" },
//   { label: "Hastsal; Delhi; ", value: "Hastsal; Delhi" },
//   { label: "Tambaram; Tamil Nadu; ", value: "Tambaram; Tamil Nadu" },
//   { label: "Adityapur; Jharkhand; ", value: "Adityapur; Jharkhand" },
//   { label: "Badalapur; Maharashtra; ", value: "Badalapur; Maharashtra" },
//   { label: "Alappuzha; Kerala; ", value: "Alappuzha; Kerala" },
//   { label: "Cuddalore; Tamil Nadu; ", value: "Cuddalore; Tamil Nadu" },
//   { label: "Silchar; Assam; ", value: "Silchar; Assam" },
//   {
//     label: "Gadag and Betigeri; Karnataka; ",
//     value: "Gadag and Betigeri; Karnataka",
//   },
//   { label: "Bahadurgarh; Haryana; ", value: "Bahadurgarh; Haryana" },
//   {
//     label: "Machilipatnam; Andhra Pradesh; ",
//     value: "Machilipatnam; Andhra Pradesh",
//   },
//   { label: "Shimla; Himachal Pradesh; ", value: "Shimla; Himachal Pradesh" },
//   { label: "Medinipur; West Bengal; ", value: "Medinipur; West Bengal" },
//   { label: "Deoli; Delhi; ", value: "Deoli; Delhi" },
//   { label: "Bharuch; Gujarat; ", value: "Bharuch; Gujarat" },
//   { label: "Hoshiarpur; Punjab; ", value: "Hoshiarpur; Punjab" },
//   { label: "Jind; Haryana; ", value: "Jind; Haryana" },
//   { label: "Chandannagar; West Bengal; ", value: "Chandannagar; West Bengal" },
//   { label: "Adoni; Andhra Pradesh; ", value: "Adoni; Andhra Pradesh" },
//   { label: "Tonk; Rajasthan; ", value: "Tonk; Rajasthan" },
//   { label: "Faizabad; Uttar Pradesh; ", value: "Faizabad; Uttar Pradesh" },
//   { label: "Tenali; Andhra Pradesh; ", value: "Tenali; Andhra Pradesh" },
//   { label: "Alandur; Tamil Nadu; ", value: "Alandur; Tamil Nadu" },
//   { label: "Kancheepuram; Tamil Nadu; ", value: "Kancheepuram; Tamil Nadu" },
//   { label: "Vapi; Gujarat; ", value: "Vapi; Gujarat" },
//   { label: "Rajnandgaon; Chhattisgarh; ", value: "Rajnandgaon; Chhattisgarh" },
//   { label: "Proddatur; Andhra Pradesh; ", value: "Proddatur; Andhra Pradesh" },
//   { label: "Navsari; Gujarat; ", value: "Navsari; Gujarat" },
//   { label: "Budaun; Uttar Pradesh; ", value: "Budaun; Uttar Pradesh" },
//   {
//     label: "Uttarpara Kotrung; West Bengal; ",
//     value: "Uttarpara Kotrung; West Bengal",
//   },
//   {
//     label: "Mahbubnagar; Andhra Pradesh; ",
//     value: "Mahbubnagar; Andhra Pradesh",
//   },
//   { label: "Erode; Tamil Nadu; ", value: "Erode; Tamil Nadu" },
//   { label: "Batala; Punjab; ", value: "Batala; Punjab" },
//   { label: "Saharsa; Bihar; ", value: "Saharsa; Bihar" },
//   {
//     label: "Haldwani and Kathgodam; Uttarakhand; ",
//     value: "Haldwani and Kathgodam; Uttarakhand",
//   },
//   { label: "Vidisha; Madhya Pradesh; ", value: "Vidisha; Madhya Pradesh" },
//   { label: "Thanesar; Haryana; ", value: "Thanesar; Haryana" },
//   { label: "Kishangarh; Rajasthan; ", value: "Kishangarh; Rajasthan" },
//   { label: "Dallo Pura; Delhi; ", value: "Dallo Pura; Delhi" },
//   { label: "Veraval; Gujarat; ", value: "Veraval; Gujarat" },
//   { label: "Banda; Uttar Pradesh; ", value: "Banda; Uttar Pradesh" },
//   { label: "Chittoor; Andhra Pradesh; ", value: "Chittoor; Andhra Pradesh" },
//   { label: "Krishnanagar; West Bengal; ", value: "Krishnanagar; West Bengal" },
//   { label: "Barrackpur; West Bengal; ", value: "Barrackpur; West Bengal" },
//   { label: "Lakhimpur; Uttar Pradesh; ", value: "Lakhimpur; Uttar Pradesh" },
//   { label: "Santipur; West Bengal; ", value: "Santipur; West Bengal" },
//   { label: "Porbandar; Gujarat; ", value: "Porbandar; Gujarat" },
//   { label: "Hindupur; Andhra Pradesh; ", value: "Hindupur; Andhra Pradesh" },
//   { label: "Balurghat; West Bengal; ", value: "Balurghat; West Bengal" },
//   { label: "Bhadravati; Karnataka; ", value: "Bhadravati; Karnataka" },
//   { label: "Hanumangarh; Rajasthan; ", value: "Hanumangarh; Rajasthan" },
//   { label: "Moga; Punjab; ", value: "Moga; Punjab" },
//   { label: "Pathankot; Punjab; ", value: "Pathankot; Punjab" },
//   { label: "Hajipur; Bihar; ", value: "Hajipur; Bihar" },
//   { label: "Sasaram; Bihar; ", value: "Sasaram; Bihar" },
//   { label: "Habra; West Bengal; ", value: "Habra; West Bengal" },
//   { label: "Bid; Maharashtra; ", value: "Bid; Maharashtra" },
//   { label: "Mohali; Punjab; ", value: "Mohali; Punjab" },
//   { label: "Burari; Delhi; ", value: "Burari; Delhi" },
//   { label: "Beawar; Rajasthan; ", value: "Beawar; Rajasthan" },
//   { label: "Abohar; Punjab; ", value: "Abohar; Punjab" },
//   {
//     label: "Tiruvannamalai; Tamil Nadu; ",
//     value: "Tiruvannamalai; Tamil Nadu",
//   },
//   { label: "Jamuria; West Bengal; ", value: "Jamuria; West Bengal" },
//   { label: "Kaithal; Haryana; ", value: "Kaithal; Haryana" },
//   { label: "Godhra; Gujarat; ", value: "Godhra; Gujarat" },
//   { label: "Bhuj; Gujarat; ", value: "Bhuj; Gujarat" },
//   { label: "Robertson Pet; Karnataka; ", value: "Robertson Pet; Karnataka" },
//   { label: "Shillong; Meghalaya; ", value: "Shillong; Meghalaya" },
//   { label: "Rewari; Haryana; ", value: "Rewari; Haryana" },
//   { label: "Hazaribag; Jharkhand; ", value: "Hazaribag; Jharkhand" },
//   {
//     label: "Bhimavaram; Andhra Pradesh; ",
//     value: "Bhimavaram; Andhra Pradesh",
//   },
//   { label: "Mandsaur; Madhya Pradesh; ", value: "Mandsaur; Madhya Pradesh" },
//   { label: "Chas; Jharkhand; ", value: "Chas; Jharkhand" },
//   { label: "Rudrapur; Uttarakhand; ", value: "Rudrapur; Uttarakhand" },
//   { label: "Chitradurga; Karnataka; ", value: "Chitradurga; Karnataka" },
//   { label: "Kumbakonam; Tamil Nadu; ", value: "Kumbakonam; Tamil Nadu" },
//   { label: "Dibrugarh; Assam; ", value: "Dibrugarh; Assam" },
//   { label: "Kolar; Karnataka; ", value: "Kolar; Karnataka" },
//   {
//     label: "Chhindwara; Madhya Pradesh; ",
//     value: "Chhindwara; Madhya Pradesh",
//   },
//   { label: "Bankura; West Bengal; ", value: "Bankura; West Bengal" },
//   { label: "Mandya; Karnataka; ", value: "Mandya; Karnataka" },
//   { label: "Dehri; Bihar; ", value: "Dehri; Bihar" },
//   { label: "Raigarh; Chhattisgarh; ", value: "Raigarh; Chhattisgarh" },
//   {
//     label: "Madanapalle; Andhra Pradesh; ",
//     value: "Madanapalle; Andhra Pradesh",
//   },
//   { label: "Nalgonda; Andhra Pradesh; ", value: "Nalgonda; Andhra Pradesh" },
//   { label: "Hathras; Uttar Pradesh; ", value: "Hathras; Uttar Pradesh" },
//   { label: "Malerkotla; Punjab; ", value: "Malerkotla; Punjab" },
//   { label: "Siwan; Bihar; ", value: "Siwan; Bihar" },
//   {
//     label: "Chhattarpur; Madhya Pradesh; ",
//     value: "Chhattarpur; Madhya Pradesh",
//   },
//   { label: "Hassan; Karnataka; ", value: "Hassan; Karnataka" },
//   { label: "Lalitpur; Uttar Pradesh; ", value: "Lalitpur; Uttar Pradesh" },
//   { label: "Gondiya; Maharashtra; ", value: "Gondiya; Maharashtra" },
//   {
//     label: "North Barrackpur; West Bengal; ",
//     value: "North Barrackpur; West Bengal",
//   },
//   { label: "Bettiah; Bihar; ", value: "Bettiah; Bihar" },
//   { label: "Palakkad; Kerala; ", value: "Palakkad; Kerala" },
//   { label: "Rajapalayam; Tamil Nadu; ", value: "Rajapalayam; Tamil Nadu" },
//   { label: "Botad; Gujarat; ", value: "Botad; Gujarat" },
//   { label: "Modinagar; Uttar Pradesh; ", value: "Modinagar; Uttar Pradesh" },
//   { label: "Deoria; Uttar Pradesh; ", value: "Deoria; Uttar Pradesh" },
//   { label: "Raniganj; West Bengal; ", value: "Raniganj; West Bengal" },
//   { label: "Palwal; Haryana; ", value: "Palwal; Haryana" },
//   { label: "Khanna; Punjab; ", value: "Khanna; Punjab" },
//   { label: "Neemuch; Madhya Pradesh; ", value: "Neemuch; Madhya Pradesh" },
//   { label: "Pilibhit; Uttar Pradesh; ", value: "Pilibhit; Uttar Pradesh" },
//   { label: "Mustafabad; Delhi; ", value: "Mustafabad; Delhi" },
//   { label: "Hardoi; Uttar Pradesh; ", value: "Hardoi; Uttar Pradesh" },
//   { label: "Guntakal; Andhra Pradesh; ", value: "Guntakal; Andhra Pradesh" },
//   { label: "Pithampur; Madhya Pradesh; ", value: "Pithampur; Madhya Pradesh" },
//   { label: "Motihari; Bihar; ", value: "Motihari; Bihar" },
//   { label: "Dhaulpur; Rajasthan; ", value: "Dhaulpur; Rajasthan" },
//   {
//     label: "Srikakulam; Andhra Pradesh; ",
//     value: "Srikakulam; Andhra Pradesh",
//   },
//   { label: "Nabadwip; West Bengal; ", value: "Nabadwip; West Bengal" },
//   { label: "Patan; Gujarat; ", value: "Patan; Gujarat" },
//   { label: "Jagdalpur; Chhattisgarh; ", value: "Jagdalpur; Chhattisgarh" },
//   { label: "Udupi; Karnataka; ", value: "Udupi; Karnataka" },
//   { label: "Basirhat; West Bengal; ", value: "Basirhat; West Bengal" },
//   { label: "Damoh; Madhya Pradesh; ", value: "Damoh; Madhya Pradesh" },
//   { label: "Halisahar; West Bengal; ", value: "Halisahar; West Bengal" },
//   { label: "Jagadhri; Haryana; ", value: "Jagadhri; Haryana" },
//   { label: "Rishra; West Bengal; ", value: "Rishra; West Bengal" },
//   { label: "Kurichi; Tamil Nadu; ", value: "Kurichi; Tamil Nadu" },
//   { label: "Dimapur; Nagaland; ", value: "Dimapur; Nagaland" },
//   { label: "Palanpur; Gujarat; ", value: "Palanpur; Gujarat" },
//   {
//     label: "Dharmavaram; Andhra Pradesh; ",
//     value: "Dharmavaram; Andhra Pradesh",
//   },
//   { label: "Gokal Pur; Delhi; ", value: "Gokal Pur; Delhi" },
//   { label: "Kashipur; Uttarakhand; ", value: "Kashipur; Uttarakhand" },
//   {
//     label: "Ashokenagar Kalyangarh; West Bengal; ",
//     value: "Ashokenagar Kalyangarh; West Bengal",
//   },
//   { label: "Baidyabati; West Bengal; ", value: "Baidyabati; West Bengal" },
//   { label: "Sawai Madhopur; Rajasthan; ", value: "Sawai Madhopur; Rajasthan" },
//   { label: "Puruliya; West Bengal; ", value: "Puruliya; West Bengal" },
//   { label: "Mandoli; Delhi; ", value: "Mandoli; Delhi" },
//   { label: "Mainpuri; Uttar Pradesh; ", value: "Mainpuri; Uttar Pradesh" },
//   { label: "Kanchrapara; West Bengal; ", value: "Kanchrapara; West Bengal" },
//   { label: "Satara; Maharashtra; ", value: "Satara; Maharashtra" },
//   { label: "Churu; Rajasthan; ", value: "Churu; Rajasthan" },
//   { label: "Madavaram; Tamil Nadu; ", value: "Madavaram; Tamil Nadu" },
//   { label: "Gangapur; Rajasthan; ", value: "Gangapur; Rajasthan" },
//   { label: "Dabgram; West Bengal; ", value: "Dabgram; West Bengal" },
//   { label: "Darjiling; West Bengal; ", value: "Darjiling; West Bengal" },
//   { label: "Barshi; Maharashtra; ", value: "Barshi; Maharashtra" },
//   { label: "Etah; Uttar Pradesh; ", value: "Etah; Uttar Pradesh" },
//   { label: "Jhunjhunun; Rajasthan; ", value: "Jhunjhunun; Rajasthan" },
//   { label: "Chikmagalur; Karnataka; ", value: "Chikmagalur; Karnataka" },
//   { label: "Jetpur Navagadh; Gujarat; ", value: "Jetpur Navagadh; Gujarat" },
//   { label: "Roorkee; Uttarakhand; ", value: "Roorkee; Uttarakhand" },
//   { label: "Gudivada; Andhra Pradesh; ", value: "Gudivada; Andhra Pradesh" },
//   { label: "Baleshwar; Odisha; ", value: "Baleshwar; Odisha" },
//   { label: "Baran; Rajasthan; ", value: "Baran; Rajasthan" },
//   {
//     label: "Hoshangabad; Madhya Pradesh; ",
//     value: "Hoshangabad; Madhya Pradesh",
//   },
//   { label: "Nagaon; Assam; ", value: "Nagaon; Assam" },
//   { label: "Pudukkottai; Tamil Nadu; ", value: "Pudukkottai; Tamil Nadu" },
//   { label: "Adilabad; Andhra Pradesh; ", value: "Adilabad; Andhra Pradesh" },
//   { label: "Hosur; Tamil Nadu; ", value: "Hosur; Tamil Nadu" },
//   { label: "Muktsar; Punjab; ", value: "Muktsar; Punjab" },
//   { label: "Yavatmal; Maharashtra; ", value: "Yavatmal; Maharashtra" },
//   { label: "Titagarh; West Bengal; ", value: "Titagarh; West Bengal" },
//   { label: "Barnala; Punjab; ", value: "Barnala; Punjab" },
//   { label: "Chittaurgarh; Rajasthan; ", value: "Chittaurgarh; Rajasthan" },
//   {
//     label: "Narasaraopet; Andhra Pradesh; ",
//     value: "Narasaraopet; Andhra Pradesh",
//   },
//   { label: "Dum Dum; West Bengal; ", value: "Dum Dum; West Bengal" },
//   { label: "Basti; Uttar Pradesh; ", value: "Basti; Uttar Pradesh" },
//   { label: "Valsad; Gujarat; ", value: "Valsad; Gujarat" },
//   { label: "Ambur; Tamil Nadu; ", value: "Ambur; Tamil Nadu" },
//   { label: "Giridih; Jharkhand; ", value: "Giridih; Jharkhand" },
//   { label: "Chandausi; Uttar Pradesh; ", value: "Chandausi; Uttar Pradesh" },
//   { label: "Gonda; Uttar Pradesh; ", value: "Gonda; Uttar Pradesh" },
//   { label: "Bally Town; West Bengal; ", value: "Bally Town; West Bengal" },
//   { label: "Kalol; Gujarat; ", value: "Kalol; Gujarat" },
//   { label: "Bagaha; Bihar; ", value: "Bagaha; Bihar" },
//   { label: "Ambikapur; Chhattisgarh; ", value: "Ambikapur; Chhattisgarh" },
//   { label: "Achalpur; Maharashtra; ", value: "Achalpur; Maharashtra" },
//   { label: "Gondal; Gujarat; ", value: "Gondal; Gujarat" },
//   { label: "Bagalkot; Karnataka; ", value: "Bagalkot; Karnataka" },
//   { label: "Osmanabad; Maharashtra; ", value: "Osmanabad; Maharashtra" },
//   { label: "Akbarpur; Uttar Pradesh; ", value: "Akbarpur; Uttar Pradesh" },
//   { label: "Champdani; West Bengal; ", value: "Champdani; West Bengal" },
//   { label: "Deesa; Gujarat; ", value: "Deesa; Gujarat" },
//   { label: "Khurja; Uttar Pradesh; ", value: "Khurja; Uttar Pradesh" },
//   { label: "Nandurbar; Maharashtra; ", value: "Nandurbar; Maharashtra" },
//   { label: "Azamgarh; Uttar Pradesh; ", value: "Azamgarh; Uttar Pradesh" },
//   { label: "Ghazipur; Uttar Pradesh; ", value: "Ghazipur; Uttar Pradesh" },
//   { label: "Delhi Cantonment; Delhi; ", value: "Delhi Cantonment; Delhi" },
//   { label: "Firozpur; Punjab; ", value: "Firozpur; Punjab" },
//   { label: "Baripada; Odisha; ", value: "Baripada; Odisha" },
//   {
//     label: "Mughalsarai; Uttar Pradesh; ",
//     value: "Mughalsarai; Uttar Pradesh",
//   },
//   {
//     label: "Anantnag; Jammu and Kashmir; ",
//     value: "Anantnag; Jammu and Kashmir",
//   },
//   { label: "Sehore; Madhya Pradesh; ", value: "Sehore; Madhya Pradesh" },
//   { label: "Bongaon; West Bengal; ", value: "Bongaon; West Bengal" },
//   {
//     label: "Kanpur Cantonment; Uttar Pradesh; ",
//     value: "Kanpur Cantonment; Uttar Pradesh",
//   },
//   { label: "Khardaha; West Bengal; ", value: "Khardaha; West Bengal" },
//   { label: "Tadpatri; Andhra Pradesh; ", value: "Tadpatri; Andhra Pradesh" },
//   {
//     label: "Port Blair; Andaman and Nicobar Islands; ",
//     value: "Port Blair; Andaman and Nicobar Islands",
//   },
//   { label: "Sultanpur; Uttar Pradesh; ", value: "Sultanpur; Uttar Pradesh" },
//   { label: "Bhadrak; Odisha; ", value: "Bhadrak; Odisha" },
//   { label: "Shikohabad; Uttar Pradesh; ", value: "Shikohabad; Uttar Pradesh" },
//   { label: "Jalpaiguri; West Bengal; ", value: "Jalpaiguri; West Bengal" },
//   { label: "Shamli; Uttar Pradesh; ", value: "Shamli; Uttar Pradesh" },
//   { label: "Karaikkudi; Tamil Nadu; ", value: "Karaikkudi; Tamil Nadu" },
//   { label: "Khargone; Madhya Pradesh; ", value: "Khargone; Madhya Pradesh" },
//   { label: "Wardha; Maharashtra; ", value: "Wardha; Maharashtra" },
//   { label: "Ranibennur; Karnataka; ", value: "Ranibennur; Karnataka" },
//   { label: "Kishanganj; Bihar; ", value: "Kishanganj; Bihar" },
//   { label: "Neyveli; Tamil Nadu; ", value: "Neyveli; Tamil Nadu" },
//   { label: "Amreli; Gujarat; ", value: "Amreli; Gujarat" },
//   { label: "Suryapet; Telangana; ", value: "Suryapet; Telangana" },
//   { label: "Gangawati; Karnataka; ", value: "Gangawati; Karnataka" },
//   { label: "Hindaun; Rajasthan; ", value: "Hindaun; Rajasthan" },
//   { label: "Jamalpur; Bihar; ", value: "Jamalpur; Bihar" },
//   { label: "Bhiwadi; Rajasthan; ", value: "Bhiwadi; Rajasthan" },
//   { label: "Ballia; Uttar Pradesh; ", value: "Ballia; Uttar Pradesh" },
//   { label: "Bansberia; West Bengal; ", value: "Bansberia; West Bengal" },
//   {
//     label: "Tadepalligudem; Andhra Pradesh; ",
//     value: "Tadepalligudem; Andhra Pradesh",
//   },
//   { label: "Miryalaguda; Telangana; ", value: "Miryalaguda; Telangana" },
//   { label: "Baraut; Uttar Pradesh; ", value: "Baraut; Uttar Pradesh" },
//   { label: "Udgir; Maharashtra; ", value: "Udgir; Maharashtra" },
//   { label: "Betul; Madhya Pradesh; ", value: "Betul; Madhya Pradesh" },
//   { label: "Bundi; Rajasthan; ", value: "Bundi; Rajasthan" },
//   { label: "Jehanabad; Bihar; ", value: "Jehanabad; Bihar" },
//   { label: "Ambala Sadar; Haryana; ", value: "Ambala Sadar; Haryana" },
//   { label: "Nagapattinam; Tamil Nadu; ", value: "Nagapattinam; Tamil Nadu" },
//   { label: "Nagaur; Rajasthan; ", value: "Nagaur; Rajasthan" },
//   { label: "Buxar; Bihar; ", value: "Buxar; Bihar" },
//   { label: "Seoni; Madhya Pradesh; ", value: "Seoni; Madhya Pradesh" },
//   { label: "Aurangabad; Bihar; ", value: "Aurangabad; Bihar" },
//   {
//     label: "Greater Noida; Uttar Pradesh; ",
//     value: "Greater Noida; Uttar Pradesh",
//   },
//   { label: "Hinganghat; Maharashtra; ", value: "Hinganghat; Maharashtra" },
//   { label: "Sujangarh; Rajasthan; ", value: "Sujangarh; Rajasthan" },
//   { label: "Bhadreswar; West Bengal; ", value: "Bhadreswar; West Bengal" },
//   {
//     label: "Chilakaluripet; Andhra Pradesh; ",
//     value: "Chilakaluripet; Andhra Pradesh",
//   },
//   { label: "Kasganj; Uttar Pradesh; ", value: "Kasganj; Uttar Pradesh" },
//   { label: "Kalyani; West Bengal; ", value: "Kalyani; West Bengal" },
//   { label: "Gangtok; Sikkim; ", value: "Gangtok; Sikkim" },
//   { label: "Datia; Madhya Pradesh; ", value: "Datia; Madhya Pradesh" },
//   { label: "Nagda; Madhya Pradesh; ", value: "Nagda; Madhya Pradesh" },
//   { label: "Banswara; Rajasthan; ", value: "Banswara; Rajasthan" },
//   { label: "Kapurthala; Punjab; ", value: "Kapurthala; Punjab" },
//   { label: "New Delhi; Delhi; ", value: "New Delhi; Delhi" },
//   {
//     label: "Vijayanagaram; Andhra Pradesh; ",
//     value: "Vijayanagaram; Andhra Pradesh",
//   },
//   { label: "Nagarcoil; Tamil Nadu; ", value: "Nagarcoil; Tamil Nadu" },
//   { label: "Murwara; Uttar Pradesh; ", value: "Murwara; Uttar Pradesh" },
//   { label: "Malda; West Bengal; ", value: "Malda; West Bengal" },
//   { label: "Sambalpur; Orissa; ", value: "Sambalpur; Orissa" },
//   { label: "Danapur; Bihar; ", value: "Danapur; Bihar" },
//   { label: "Kottayam; Kerala; ", value: "Kottayam; Kerala" },
//   { label: "Tadipatri; Andhra Pradesh; ", value: "Tadipatri; Andhra Pradesh" },
//   { label: "Karaikudi; Tamil Nadu; ", value: "Karaikudi; Tamil Nadu" },
//   { label: "Kavali; Andhra Pradesh; ", value: "Kavali; Andhra Pradesh" },
//   { label: "Amaravati; Andhra Pradesh; ", value: "Amaravati; Andhra Pradesh" },
//   { label: "Mon; Nagaland; ", value: "Mon; Nagaland" },
//   { label: "Adilabad; Telangana; ", value: "Adilabad; Telangana" },
//   {
//     label: "Agar Malwa; Madhya Pradesh; ",
//     value: "Agar Malwa; Madhya Pradesh",
//   },
//   { label: "Agartala ; Tripura; ", value: "Agartala ; Tripura" },
//   {
//     label: "Agra; Andaman and Nico.In.; ",
//     value: "Agra; Andaman and Nico.In.",
//   },
//   { label: "Ahmed Nagar; Maharashtra; ", value: "Ahmed Nagar; Maharashtra" },
//   { label: "Ahmedabad City; Gujarat; ", value: "Ahmedabad City; Gujarat" },
//   { label: "Aizawal; Mizoram; ", value: "Aizawal; Mizoram" },
//   { label: "Alirajpur; Madhya Pradesh; ", value: "Alirajpur; Madhya Pradesh" },
//   { label: "Allahabad; Uttar Pradesh; ", value: "Allahabad; Uttar Pradesh" },
//   { label: "Almora; Uttarakhand; ", value: "Almora; Uttarakhand" },
//   { label: "Ambala City; Haryana; ", value: "Ambala City; Haryana" },
//   {
//     label: "Ambedkar Nagar; Uttar Pradesh; ",
//     value: "Ambedkar Nagar; Uttar Pradesh",
//   },
//   { label: "Amethi; Uttar Pradesh; ", value: "Amethi; Uttar Pradesh" },
//   { label: "Amlapuram; Andhra Pradesh; ", value: "Amlapuram; Andhra Pradesh" },
//   {
//     label: "Ananthapur; Andhra Pradesh; ",
//     value: "Ananthapur; Andhra Pradesh",
//   },
//   {
//     label: "Ananthnag; Jammu and Kashmir; ",
//     value: "Ananthnag; Jammu and Kashmir",
//   },
//   { label: "Angul; Odisha; ", value: "Angul; Odisha" },
//   { label: "Anuppur; Madhya Pradesh; ", value: "Anuppur; Madhya Pradesh" },
//   { label: "Araria; Bihar; ", value: "Araria; Bihar" },
//   { label: "Aravalli; Gujarat; ", value: "Aravalli; Gujarat" },
//   { label: "Ariyalur; Tamil Nadu; ", value: "Ariyalur; Tamil Nadu" },
//   { label: "Arsikere; Karnataka; ", value: "Arsikere; Karnataka" },
//   { label: "Arwal; Bihar; ", value: "Arwal; Bihar" },
//   {
//     label: "Ashok Nagar; Madhya Pradesh; ",
//     value: "Ashok Nagar; Madhya Pradesh",
//   },
//   { label: "Athani; Karnataka; ", value: "Athani; Karnataka" },
//   { label: "Auraiya; Uttar Pradesh; ", value: "Auraiya; Uttar Pradesh" },
//   { label: "Auranagabad; Bihar; ", value: "Auranagabad; Bihar" },
//   { label: "Aurangabad; Uttar Pradesh; ", value: "Aurangabad; Uttar Pradesh" },
//   {
//     label: "Avanigadda; Andhra Pradesh; ",
//     value: "Avanigadda; Andhra Pradesh",
//   },
//   { label: "Bageshwar; Uttarakhand; ", value: "Bageshwar; Uttarakhand" },
//   { label: "Bagpat; Uttar Pradesh; ", value: "Bagpat; Uttar Pradesh" },
//   { label: "Bailhongal; Karnataka; ", value: "Bailhongal; Karnataka" },
//   { label: "Baksa; Assam; ", value: "Baksa; Assam" },
//   { label: "Balaghat; Madhya Pradesh; ", value: "Balaghat; Madhya Pradesh" },
//   { label: "Balangir; Odisha; ", value: "Balangir; Odisha" },
//   { label: "Balasore; Odisha; ", value: "Balasore; Odisha" },
//   { label: "Baleswar; Odisha; ", value: "Baleswar; Odisha" },
//   { label: "Ballari; Karnataka; ", value: "Ballari; Karnataka" },
//   { label: "Ballia; Bihar; ", value: "Ballia; Bihar" },
//   { label: "Balod; Chattisgarh; ", value: "Balod; Chattisgarh" },
//   { label: "Balod Bazer; Chattisgarh; ", value: "Balod Bazer; Chattisgarh" },
//   { label: "Balrampur; Chattisgarh; ", value: "Balrampur; Chattisgarh" },
//   { label: "Balrampur; Uttar Pradesh; ", value: "Balrampur; Uttar Pradesh" },
//   { label: "Banaskantha; Gujarat; ", value: "Banaskantha; Gujarat" },
//   {
//     label: "Bandipur; Jammu and Kashmir; ",
//     value: "Bandipur; Jammu and Kashmir",
//   },
//   { label: "Banka; Bihar; ", value: "Banka; Bihar" },
//   { label: "Banka; Gujarat; ", value: "Banka; Gujarat" },
//   { label: "Bapatla; Andhra Pradesh; ", value: "Bapatla; Andhra Pradesh" },
//   { label: "Barabanki; Uttar Pradesh; ", value: "Barabanki; Uttar Pradesh" },
//   {
//     label: "Baramulla; Jammu and Kashmir; ",
//     value: "Baramulla; Jammu and Kashmir",
//   },
//   { label: "Bargarh; Odisha; ", value: "Bargarh; Odisha" },
//   { label: "Barmer; Rajasthan; ", value: "Barmer; Rajasthan" },
//   { label: "Barpeta; Assam; ", value: "Barpeta; Assam" },
//   { label: "Barwani; Madhya Pradesh; ", value: "Barwani; Madhya Pradesh" },
//   { label: "Bastar; Chattisgarh; ", value: "Bastar; Chattisgarh" },
//   {
//     label: "Basti; Andaman and Nico.In.; ",
//     value: "Basti; Andaman and Nico.In.",
//   },
//   { label: "Basti; Chandigarh; ", value: "Basti; Chandigarh" },
//   {
//     label: "Basti; Dadra and Nagar Hav.; ",
//     value: "Basti; Dadra and Nagar Hav.",
//   },
//   { label: "Basti; Daman and Diu; ", value: "Basti; Daman and Diu" },
//   { label: "Beed; Maharashtra; ", value: "Beed; Maharashtra" },
//   { label: "Belagavi; Karnataka; ", value: "Belagavi; Karnataka" },
//   { label: "Bemetara; Chattisgarh; ", value: "Bemetara; Chattisgarh" },
//   { label: "Bengaluru; Karnataka; ", value: "Bengaluru; Karnataka" },
//   {
//     label: "Bengaluru Rural; Karnataka; ",
//     value: "Bengaluru Rural; Karnataka",
//   },
//   {
//     label: "Bhadrachalam; Andhra Pradesh; ",
//     value: "Bhadrachalam; Andhra Pradesh",
//   },
//   { label: "Bhadrachalam; Telangana; ", value: "Bhadrachalam; Telangana" },
//   { label: "Bhandara; Maharashtra; ", value: "Bhandara; Maharashtra" },
//   { label: "Bhiwani; Himachal Pradesh; ", value: "Bhiwani; Himachal Pradesh" },
//   { label: "Bhojpur; Bihar; ", value: "Bhojpur; Bihar" },
//   { label: "Bhongir; Andhra Pradesh; ", value: "Bhongir; Andhra Pradesh" },
//   { label: "Bhongir; Telangana; ", value: "Bhongir; Telangana" },
//   { label: "Bhupalpalli; Telangana; ", value: "Bhupalpalli; Telangana" },
//   { label: "Bijapur(Cgh); Chattisgarh; ", value: "Bijapur(Cgh); Chattisgarh" },
//   { label: "Bijapur(Kar); Karnataka; ", value: "Bijapur(Kar); Karnataka" },
//   { label: "Bijnor; Uttar Pradesh; ", value: "Bijnor; Uttar Pradesh" },
//   { label: "Bilaspur; Chattisgarh; ", value: "Bilaspur; Chattisgarh" },
//   {
//     label: "Bilaspur (Hp); Himachal Pradesh; ",
//     value: "Bilaspur (Hp); Himachal Pradesh",
//   },
//   { label: "Bilaspurcgh; Chattisgarh; ", value: "Bilaspurcgh; Chattisgarh" },
//   { label: "Birbhum; West Bengal; ", value: "Birbhum; West Bengal" },
//   { label: "Bishnupur; Manipur; ", value: "Bishnupur; Manipur" },
//   { label: "Bongaigaon; Assam; ", value: "Bongaigaon; Assam" },
//   { label: "Botab; Gujarat; ", value: "Botab; Gujarat" },
//   { label: "Boudh; Odisha; ", value: "Boudh; Odisha" },
//   { label: "Budgam; Jammu and Kashmir; ", value: "Budgam; Jammu and Kashmir" },
//   { label: "Buldhana; Maharashtra; ", value: "Buldhana; Maharashtra" },
//   { label: "Cachar; Assam; ", value: "Cachar; Assam" },
//   { label: "Central Delhi; Delhi; ", value: "Central Delhi; Delhi" },
//   { label: "Chamarajanagar; Karnataka; ", value: "Chamarajanagar; Karnataka" },
//   { label: "Chamba; Himachal Pradesh; ", value: "Chamba; Himachal Pradesh" },
//   { label: "Chamoli; Uttarakhand; ", value: "Chamoli; Uttarakhand" },
//   { label: "Champawat; Uttarakhand; ", value: "Champawat; Uttarakhand" },
//   { label: "Champhai; Mizoram; ", value: "Champhai; Mizoram" },
//   { label: "Chamrajnagar; Karnataka; ", value: "Chamrajnagar; Karnataka" },
//   { label: "Chandauli; Uttar Pradesh; ", value: "Chandauli; Uttar Pradesh" },
//   { label: "Chandel; Manipur; ", value: "Chandel; Manipur" },
//   { label: "Chandighar; Chandigarh; ", value: "Chandighar; Chandigarh" },
//   { label: "Chandighar; Punjab; ", value: "Chandighar; Punjab" },
//   {
//     label: "Changlang; Arunachal Pradesh; ",
//     value: "Changlang; Arunachal Pradesh",
//   },
//   { label: "Chatra; Jharkhand; ", value: "Chatra; Jharkhand" },
//   {
//     label: "Chhatarpur; Madhya Pradesh; ",
//     value: "Chhatarpur; Madhya Pradesh",
//   },
//   { label: "Chhota Udepur; Gujarat; ", value: "Chhota Udepur; Gujarat" },
//   { label: "Chhotaudepur; Gujarat; ", value: "Chhotaudepur; Gujarat" },
//   { label: "Chickmagalur; Karnataka; ", value: "Chickmagalur; Karnataka" },
//   { label: "Chikkaballapur; Karnataka; ", value: "Chikkaballapur; Karnataka" },
//   { label: "Chikkamagaluru; Karnataka; ", value: "Chikkamagaluru; Karnataka" },
//   { label: "Chitrakoot; Uttar Pradesh; ", value: "Chitrakoot; Uttar Pradesh" },
//   { label: "Chittorgarh; Rajasthan; ", value: "Chittorgarh; Rajasthan" },
//   { label: "Churachandpur; Manipur; ", value: "Churachandpur; Manipur" },
//   { label: "Cooch Behar; West Bengal; ", value: "Cooch Behar; West Bengal" },
//   {
//     label: "Dadra  Nagar Haveli; Dadra and Nagar Hav.; ",
//     value: "Dadra  Nagar Haveli; Dadra and Nagar Hav.",
//   },
//   { label: "Dahod; Gujarat; ", value: "Dahod; Gujarat" },
//   {
//     label: "Dakshina Kannada; Karnataka; ",
//     value: "Dakshina Kannada; Karnataka",
//   },
//   { label: "Daman; Daman and Diu; ", value: "Daman; Daman and Diu" },
//   { label: "Dangs; Gujarat; ", value: "Dangs; Gujarat" },
//   { label: "Dantewada; Chattisgarh; ", value: "Dantewada; Chattisgarh" },
//   { label: "Darbhanga; Goa; ", value: "Darbhanga; Goa" },
//   { label: "Darbhanga; Gujarat; ", value: "Darbhanga; Gujarat" },
//   { label: "Darbhanga; Haryana; ", value: "Darbhanga; Haryana" },
//   {
//     label: "Darbhanga; Himachal Pradesh; ",
//     value: "Darbhanga; Himachal Pradesh",
//   },
//   {
//     label: "Darbhanga; Jammu and Kashmir; ",
//     value: "Darbhanga; Jammu and Kashmir",
//   },
//   { label: "Darbhanga; Karnataka; ", value: "Darbhanga; Karnataka" },
//   { label: "Darbhanga; Kerala; ", value: "Darbhanga; Kerala" },
//   { label: "Darbhanga; Madhya Pradesh; ", value: "Darbhanga; Madhya Pradesh" },
//   { label: "Darbhanga; Maharashtra; ", value: "Darbhanga; Maharashtra" },
//   { label: "Darjeeling; West Bengal; ", value: "Darjeeling; West Bengal" },
//   { label: "Darrang; Assam; ", value: "Darrang; Assam" },
//   { label: "Dausa; Rajasthan; ", value: "Dausa; Rajasthan" },
//   { label: "Davangere; Karnataka; ", value: "Davangere; Karnataka" },
//   { label: "Debagarh; Odisha; ", value: "Debagarh; Odisha" },
//   { label: "Deoghar; Bihar; ", value: "Deoghar; Bihar" },
//   { label: "Devbhoomi Dwerka; Gujarat; ", value: "Devbhoomi Dwerka; Gujarat" },
//   { label: "Dhalai; Tripura; ", value: "Dhalai; Tripura" },
//   { label: "Dhamtari; Chattisgarh; ", value: "Dhamtari; Chattisgarh" },
//   { label: "Dhar; Madhya Pradesh; ", value: "Dhar; Madhya Pradesh" },
//   { label: "Dharmanagar; Tripura; ", value: "Dharmanagar; Tripura" },
//   { label: "Dharmapuri; Tamil Nadu; ", value: "Dharmapuri; Tamil Nadu" },
//   { label: "Dharwad; Karnataka; ", value: "Dharwad; Karnataka" },
//   { label: "Dhemaji; Assam; ", value: "Dhemaji; Assam" },
//   { label: "Dhenkanal; Odisha; ", value: "Dhenkanal; Odisha" },
//   { label: "Dholpur; Rajasthan; ", value: "Dholpur; Rajasthan" },
//   { label: "Dhubri; Assam; ", value: "Dhubri; Assam" },
//   {
//     label: "Dibang Valley; Arunachal Pradesh; ",
//     value: "Dibang Valley; Arunachal Pradesh",
//   },
//   {
//     label: "Dima Hasso - North Cachar Hill; Assam; ",
//     value: "Dima Hasso - North Cachar Hill; Assam",
//   },
//   { label: "Dimapur; Sikkim; ", value: "Dimapur; Sikkim" },
//   { label: "Dindori; Madhya Pradesh; ", value: "Dindori; Madhya Pradesh" },
//   { label: "Diu; Daman and Diu; ", value: "Diu; Daman and Diu" },
//   { label: "Doda; Jammu and Kashmir; ", value: "Doda; Jammu and Kashmir" },
//   { label: "Dumka; Jharkhand; ", value: "Dumka; Jharkhand" },
//   { label: "Dungarpur; Rajasthan; ", value: "Dungarpur; Rajasthan" },
//   { label: "Durg; Chattisgarh; ", value: "Durg; Chattisgarh" },
//   { label: "East Champaran; Bihar; ", value: "East Champaran; Bihar" },
//   { label: "East Garo Hills; Megalaya; ", value: "East Garo Hills; Megalaya" },
//   {
//     label: "East Godavari; Andhra Pradesh; ",
//     value: "East Godavari; Andhra Pradesh",
//   },
//   {
//     label: "East Jaintia Hills; Megalaya; ",
//     value: "East Jaintia Hills; Megalaya",
//   },
//   {
//     label: "East Kameng; Arunachal Pradesh; ",
//     value: "East Kameng; Arunachal Pradesh",
//   },
//   {
//     label: "East Khasi Hills; Megalaya; ",
//     value: "East Khasi Hills; Megalaya",
//   },
//   {
//     label: "East Midnapore; West Bengal; ",
//     value: "East Midnapore; West Bengal",
//   },
//   {
//     label: "East Siang; Arunachal Pradesh; ",
//     value: "East Siang; Arunachal Pradesh",
//   },
//   { label: "East Sikkim; Sikkim; ", value: "East Sikkim; Sikkim" },
//   { label: "East Sikkim; West Bengal; ", value: "East Sikkim; West Bengal" },
//   { label: "East Singhbhum; Jharkhand; ", value: "East Singhbhum; Jharkhand" },
//   { label: "Ernakulam; Kerala; ", value: "Ernakulam; Kerala" },
//   { label: "Faridkot; Punjab; ", value: "Faridkot; Punjab" },
//   { label: "Fatehabad; Haryana; ", value: "Fatehabad; Haryana" },
//   { label: "Fatehgarh Sahib; Punjab; ", value: "Fatehgarh Sahib; Punjab" },
//   { label: "Fazilka; Punjab; ", value: "Fazilka; Punjab" },
//   { label: "Ferozepur; Punjab; ", value: "Ferozepur; Punjab" },
//   { label: "Gadag; Karnataka; ", value: "Gadag; Karnataka" },
//   { label: "Gadchiroli; Maharashtra; ", value: "Gadchiroli; Maharashtra" },
//   { label: "Gadwal; Telangana; ", value: "Gadwal; Telangana" },
//   { label: "Gajapati; Odisha; ", value: "Gajapati; Odisha" },
//   {
//     label: "Ganderbal; Jammu and Kashmir; ",
//     value: "Ganderbal; Jammu and Kashmir",
//   },
//   { label: "Gandhi Nagar; Gujarat; ", value: "Gandhi Nagar; Gujarat" },
//   { label: "Ganjam; Odisha; ", value: "Ganjam; Odisha" },
//   { label: "Garhwa; Jharkhand; ", value: "Garhwa; Jharkhand" },
//   { label: "Gariaband; Chattisgarh; ", value: "Gariaband; Chattisgarh" },
//   {
//     label: "Gautam Buddha Nagar; Uttar Pradesh; ",
//     value: "Gautam Buddha Nagar; Uttar Pradesh",
//   },
//   { label: "Gir Somnath; Gujarat; ", value: "Gir Somnath; Gujarat" },
//   { label: "Giridh; Jharkhand; ", value: "Giridh; Jharkhand" },
//   { label: "Goalpara; Assam; ", value: "Goalpara; Assam" },
//   { label: "Godda; Jharkhand; ", value: "Godda; Jharkhand" },
//   { label: "Golaghat; Assam; ", value: "Golaghat; Assam" },
//   { label: "Gomati; Tripura; ", value: "Gomati; Tripura" },
//   { label: "Gomti; Tripura; ", value: "Gomti; Tripura" },
//   { label: "Gondia; Maharashtra; ", value: "Gondia; Maharashtra" },
//   { label: "Gopalganj; Bihar; ", value: "Gopalganj; Bihar" },
//   { label: "Gumla; Jharkhand; ", value: "Gumla; Jharkhand" },
//   { label: "Gurdaspur; Punjab; ", value: "Gurdaspur; Punjab" },
//   { label: "Hailakandi; Assam; ", value: "Hailakandi; Assam" },
//   { label: "Hamirpur; Uttar Pradesh; ", value: "Hamirpur; Uttar Pradesh" },
//   {
//     label: "Hamirpur(Hp); Himachal Pradesh; ",
//     value: "Hamirpur(Hp); Himachal Pradesh",
//   },
//   {
//     label: "Hamirpurhp; Himachal Pradesh; ",
//     value: "Hamirpurhp; Himachal Pradesh",
//   },
//   { label: "Hanagodu; Karnataka; ", value: "Hanagodu; Karnataka" },
//   { label: "Hanamkonda; Telangana; ", value: "Hanamkonda; Telangana" },
//   { label: "Harda; Madhya Pradesh; ", value: "Harda; Madhya Pradesh" },
//   { label: "Haridwar; Uttar Pradesh; ", value: "Haridwar; Uttar Pradesh" },
//   { label: "Haveri; Karnataka; ", value: "Haveri; Karnataka" },
//   { label: "Hazaribag; Uttarakhand; ", value: "Hazaribag; Uttarakhand" },
//   { label: "Hazaribagh; Jharkhand; ", value: "Hazaribagh; Jharkhand" },
//   { label: "Hingoli; Maharashtra; ", value: "Hingoli; Maharashtra" },
//   { label: "Hooghly; West Bengal; ", value: "Hooghly; West Bengal" },
//   { label: "Hubballi; Karnataka; ", value: "Hubballi; Karnataka" },
//   { label: "Hunsur; Karnataka; ", value: "Hunsur; Karnataka" },
//   { label: "Idukki; Kerala; ", value: "Idukki; Kerala" },
//   { label: "Imphal East; Manipur; ", value: "Imphal East; Manipur" },
//   { label: "Imphal West; Manipur; ", value: "Imphal West; Manipur" },
//   { label: "Jagatsinghapur; Odisha; ", value: "Jagatsinghapur; Odisha" },
//   { label: "Jagdalpur; Chattisgarh; ", value: "Jagdalpur; Chattisgarh" },
//   { label: "Jagtial; Telangana; ", value: "Jagtial; Telangana" },
//   { label: "Jaintia Hills; Megalaya; ", value: "Jaintia Hills; Megalaya" },
//   { label: "Jaisalmer; Rajasthan; ", value: "Jaisalmer; Rajasthan" },
//   { label: "Jajapur; Odisha; ", value: "Jajapur; Odisha" },
//   { label: "Jalaun; Uttar Pradesh; ", value: "Jalaun; Uttar Pradesh" },
//   { label: "Jalgoan; Maharashtra; ", value: "Jalgoan; Maharashtra" },
//   { label: "Jalor; Rajasthan; ", value: "Jalor; Rajasthan" },
//   { label: "Jamkhandi; Karnataka; ", value: "Jamkhandi; Karnataka" },
//   { label: "Jamtara; Jharkhand; ", value: "Jamtara; Jharkhand" },
//   { label: "Jamui; Bihar; ", value: "Jamui; Bihar" },
//   { label: "Jangaon; Telangana; ", value: "Jangaon; Telangana" },
//   {
//     label: "Janjgirchampa; Chattisgarh; ",
//     value: "Janjgirchampa; Chattisgarh",
//   },
//   {
//     label: "Janjgir-Champa; Chattisgarh; ",
//     value: "Janjgir-Champa; Chattisgarh",
//   },
//   { label: "Jaspur; Chattisgarh; ", value: "Jaspur; Chattisgarh" },
//   { label: "Jayashankar; Telangana; ", value: "Jayashankar; Telangana" },
//   { label: "Jhabua; Madhya Pradesh; ", value: "Jhabua; Madhya Pradesh" },
//   { label: "Jhajjar; Haryana; ", value: "Jhajjar; Haryana" },
//   { label: "Jhajjar; Himachal Pradesh; ", value: "Jhajjar; Himachal Pradesh" },
//   { label: "Jhajjar; Karnataka; ", value: "Jhajjar; Karnataka" },
//   { label: "Jhajjar; Kerala; ", value: "Jhajjar; Kerala" },
//   { label: "Jhajjar; Madhya Pradesh; ", value: "Jhajjar; Madhya Pradesh" },
//   { label: "Jhajjar; Maharashtra; ", value: "Jhajjar; Maharashtra" },
//   { label: "Jhajjar; Manipur; ", value: "Jhajjar; Manipur" },
//   { label: "Jhajjar; Megalaya; ", value: "Jhajjar; Megalaya" },
//   { label: "Jhajjar; Mizoram; ", value: "Jhajjar; Mizoram" },
//   { label: "Jhajjar; Nagaland; ", value: "Jhajjar; Nagaland" },
//   { label: "Jhajjar; Odisha; ", value: "Jhajjar; Odisha" },
//   { label: "Jhajjar; Punjab; ", value: "Jhajjar; Punjab" },
//   { label: "Jhajjar; Rajasthan; ", value: "Jhajjar; Rajasthan" },
//   { label: "Jhajjar; Sikkim; ", value: "Jhajjar; Sikkim" },
//   { label: "Jhajjar; Tamil Nadu; ", value: "Jhajjar; Tamil Nadu" },
//   { label: "Jhajjar; Tripura; ", value: "Jhajjar; Tripura" },
//   { label: "Jhajjar; Uttar Pradesh; ", value: "Jhajjar; Uttar Pradesh" },
//   { label: "Jhalawar; Rajasthan; ", value: "Jhalawar; Rajasthan" },
//   { label: "Jharsuguda; Odisha; ", value: "Jharsuguda; Odisha" },
//   { label: "Jhujhunu; Rajasthan; ", value: "Jhujhunu; Rajasthan" },
//   { label: "Jiribam; Manipur; ", value: "Jiribam; Manipur" },
//   { label: "Joida; Karnataka; ", value: "Joida; Karnataka" },
//   { label: "Jorhat; Assam; ", value: "Jorhat; Assam" },
//   { label: "K.V.Rangareddy; Telangana; ", value: "K.V.Rangareddy; Telangana" },
//   { label: "Kachchh; Gujarat; ", value: "Kachchh; Gujarat" },
//   { label: "Kaimur Bhabua; Bihar; ", value: "Kaimur Bhabua; Bihar" },
//   { label: "Kalaburagi; Karnataka; ", value: "Kalaburagi; Karnataka" },
//   { label: "Kalahandi; Odisha; ", value: "Kalahandi; Odisha" },
//   { label: "Kalghatgi; Karnataka; ", value: "Kalghatgi; Karnataka" },
//   { label: "Kalimpong; West Bengal; ", value: "Kalimpong; West Bengal" },
//   { label: "Kamareddy; Telangana; ", value: "Kamareddy; Telangana" },
//   { label: "Kamrup; Assam; ", value: "Kamrup; Assam" },
//   {
//     label: "Kamrup Metropolitan; Assam; ",
//     value: "Kamrup Metropolitan; Assam",
//   },
//   { label: "Kanchipuram; Tamil Nadu; ", value: "Kanchipuram; Tamil Nadu" },
//   { label: "Kandhamal; Odisha; ", value: "Kandhamal; Odisha" },
//   { label: "Kangra; Himachal Pradesh; ", value: "Kangra; Himachal Pradesh" },
//   { label: "Kanker; Chattisgarh; ", value: "Kanker; Chattisgarh" },
//   { label: "Kannauj; Uttar Pradesh; ", value: "Kannauj; Uttar Pradesh" },
//   { label: "Kannur; Kerala; ", value: "Kannur; Kerala" },
//   {
//     label: "Kanpur Dehat; Uttar Pradesh; ",
//     value: "Kanpur Dehat; Uttar Pradesh",
//   },
//   {
//     label: "Kanpur Nagar; Uttar Pradesh; ",
//     value: "Kanpur Nagar; Uttar Pradesh",
//   },
//   {
//     label: "Kanshiram Nagar; Uttar Pradesh; ",
//     value: "Kanshiram Nagar; Uttar Pradesh",
//   },
//   { label: "Kanyakumari; Tamil Nadu; ", value: "Kanyakumari; Tamil Nadu" },
//   { label: "Karaikal; Tamil Nadu; ", value: "Karaikal; Tamil Nadu" },
//   { label: "Karauli; Rajasthan; ", value: "Karauli; Rajasthan" },
//   { label: "Karbi Anglong; Assam; ", value: "Karbi Anglong; Assam" },
//   { label: "Kargil; Jammu and Kashmir; ", value: "Kargil; Jammu and Kashmir" },
//   {
//     label: "Karim Nagar; Andhra Pradesh; ",
//     value: "Karim Nagar; Andhra Pradesh",
//   },
//   { label: "Karim Nagar; Telangana; ", value: "Karim Nagar; Telangana" },
//   { label: "Karimganj; Assam; ", value: "Karimganj; Assam" },
//   { label: "Karur; Tamil Nadu; ", value: "Karur; Tamil Nadu" },
//   { label: "Kasaragod; Kerala; ", value: "Kasaragod; Kerala" },
//   { label: "Kasargod; Kerala; ", value: "Kasargod; Kerala" },
//   { label: "Kathua; Jammu and Kashmir; ", value: "Kathua; Jammu and Kashmir" },
//   { label: "Katni; Madhya Pradesh; ", value: "Katni; Madhya Pradesh" },
//   { label: "Kaushambi; Uttar Pradesh; ", value: "Kaushambi; Uttar Pradesh" },
//   { label: "Kawardha; Chattisgarh; ", value: "Kawardha; Chattisgarh" },
//   { label: "Kendrapara; Odisha; ", value: "Kendrapara; Odisha" },
//   { label: "Kendujhar; Odisha; ", value: "Kendujhar; Odisha" },
//   { label: "Khagaria; Bihar; ", value: "Khagaria; Bihar" },
//   { label: "Kheda; Gujarat; ", value: "Kheda; Gujarat" },
//   { label: "Kheri; Uttar Pradesh; ", value: "Kheri; Uttar Pradesh" },
//   { label: "Khorda; Odisha; ", value: "Khorda; Odisha" },
//   { label: "Khowai; Tripura; ", value: "Khowai; Tripura" },
//   { label: "Khunti; Jharkhand; ", value: "Khunti; Jharkhand" },
//   { label: "Kinnaur; Himachal Pradesh; ", value: "Kinnaur; Himachal Pradesh" },
//   { label: "Kiphire; Nagaland; ", value: "Kiphire; Nagaland" },
//   { label: "Kochi; Lakshadweep; ", value: "Kochi; Lakshadweep" },
//   { label: "Kodagu; Karnataka; ", value: "Kodagu; Karnataka" },
//   { label: "Koderma; Jharkhand; ", value: "Koderma; Jharkhand" },
//   { label: "Koderma; Telangana; ", value: "Koderma; Telangana" },
//   { label: "Kohima; Nagaland; ", value: "Kohima; Nagaland" },
//   { label: "Kokrajhar; Assam; ", value: "Kokrajhar; Assam" },
//   { label: "Kolasib; Mizoram; ", value: "Kolasib; Mizoram" },
//   {
//     label: "Kolkata; Andaman and Nico.In.; ",
//     value: "Kolkata; Andaman and Nico.In.",
//   },
//   { label: "Kollegal; Karnataka; ", value: "Kollegal; Karnataka" },
//   { label: "Kollegal; Kerala; ", value: "Kollegal; Kerala" },
//   { label: "Kondagaon; Chattisgarh; ", value: "Kondagaon; Chattisgarh" },
//   { label: "Koppal; Karnataka; ", value: "Koppal; Karnataka" },
//   { label: "Koraput; Odisha; ", value: "Koraput; Odisha" },
//   { label: "Korba; Chattisgarh; ", value: "Korba; Chattisgarh" },
//   { label: "Koriya; Chattisgarh; ", value: "Koriya; Chattisgarh" },
//   { label: "Kothagudem; Telangana; ", value: "Kothagudem; Telangana" },
//   {
//     label: "Kothagudem Colls; Telangana; ",
//     value: "Kothagudem Colls; Telangana",
//   },
//   { label: "Kovvur; Andhra Pradesh; ", value: "Kovvur; Andhra Pradesh" },
//   { label: "Krishna; Andhra Pradesh; ", value: "Krishna; Andhra Pradesh" },
//   { label: "Krishnagiri; Tamil Nadu; ", value: "Krishnagiri; Tamil Nadu" },
//   { label: "Kulgam; Jammu and Kashmir; ", value: "Kulgam; Jammu and Kashmir" },
//   { label: "Kullu; Himachal Pradesh; ", value: "Kullu; Himachal Pradesh" },
//   { label: "Kundgol; Karnataka; ", value: "Kundgol; Karnataka" },
//   {
//     label: "Kupwara; Jammu and Kashmir; ",
//     value: "Kupwara; Jammu and Kashmir",
//   },
//   { label: "Kurukshetra; Haryana; ", value: "Kurukshetra; Haryana" },
//   {
//     label: "Kurung Kumey; Arunachal Pradesh; ",
//     value: "Kurung Kumey; Arunachal Pradesh",
//   },
//   { label: "Kushinagar; Uttar Pradesh; ", value: "Kushinagar; Uttar Pradesh" },
//   {
//     label: "Lahul  Spiti; Himachal Pradesh; ",
//     value: "Lahul  Spiti; Himachal Pradesh",
//   },
//   { label: "Lakhimpur; Assam; ", value: "Lakhimpur; Assam" },
//   { label: "Lakhisarai; Bihar; ", value: "Lakhisarai; Bihar" },
//   { label: "Lakshadweep; Lakshadweep; ", value: "Lakshadweep; Lakshadweep" },
//   { label: "Latehar; Jharkhand; ", value: "Latehar; Jharkhand" },
//   { label: "Lawngtlai; Mizoram; ", value: "Lawngtlai; Mizoram" },
//   { label: "Leh; Jammu and Kashmir; ", value: "Leh; Jammu and Kashmir" },
//   { label: "Lohardaga; Jharkhand; ", value: "Lohardaga; Jharkhand" },
//   { label: "Lohit; Arunachal Pradesh; ", value: "Lohit; Arunachal Pradesh" },
//   { label: "Longleng; Nagaland; ", value: "Longleng; Nagaland" },
//   {
//     label: "Lower Dibang Valley; Arunachal Pradesh; ",
//     value: "Lower Dibang Valley; Arunachal Pradesh",
//   },
//   {
//     label: "Lower Subansiri; Arunachal Pradesh; ",
//     value: "Lower Subansiri; Arunachal Pradesh",
//   },
//   { label: "Lunglei; Mizoram; ", value: "Lunglei; Mizoram" },
//   { label: "Madhepura; Bihar; ", value: "Madhepura; Bihar" },
//   { label: "Madhubani; Bihar; ", value: "Madhubani; Bihar" },
//   { label: "Madikeri; Karnataka; ", value: "Madikeri; Karnataka" },
//   { label: "Mahabuababad; Telangana; ", value: "Mahabuababad; Telangana" },
//   {
//     label: "Mahabub Nagar; Andhra Pradesh; ",
//     value: "Mahabub Nagar; Andhra Pradesh",
//   },
//   { label: "Mahabub Nagar; Telangana; ", value: "Mahabub Nagar; Telangana" },
//   { label: "Mahabubabad; Telangana; ", value: "Mahabubabad; Telangana" },
//   {
//     label: "Maharajganj; Uttar Pradesh; ",
//     value: "Maharajganj; Uttar Pradesh",
//   },
//   { label: "Mahasamund; Chattisgarh; ", value: "Mahasamund; Chattisgarh" },
//   { label: "Mahe; Kerala; ", value: "Mahe; Kerala" },
//   { label: "Mahendragarh; Haryana; ", value: "Mahendragarh; Haryana" },
//   { label: "Mahisagar; Gujarat; ", value: "Mahisagar; Gujarat" },
//   { label: "Mahoba; Uttar Pradesh; ", value: "Mahoba; Uttar Pradesh" },
//   { label: "Mahrajganj; Uttar Pradesh; ", value: "Mahrajganj; Uttar Pradesh" },
//   { label: "Makdi; Chattisgarh; ", value: "Makdi; Chattisgarh" },
//   { label: "Malappuram; Kerala; ", value: "Malappuram; Kerala" },
//   { label: "Malappurm; Kerala; ", value: "Malappurm; Kerala" },
//   { label: "Malkangiri; Odisha; ", value: "Malkangiri; Odisha" },
//   { label: "Mamit; Mizoram; ", value: "Mamit; Mizoram" },
//   { label: "Mammit; Mizoram; ", value: "Mammit; Mizoram" },
//   { label: "Mancherial; Telangana; ", value: "Mancherial; Telangana" },
//   { label: "Mandi; Himachal Pradesh; ", value: "Mandi; Himachal Pradesh" },
//   { label: "Mandla; Madhya Pradesh; ", value: "Mandla; Madhya Pradesh" },
//   { label: "Mansa; Punjab; ", value: "Mansa; Punjab" },
//   { label: "Marigaon; Assam; ", value: "Marigaon; Assam" },
//   { label: "Mayurbhanj; Odisha; ", value: "Mayurbhanj; Odisha" },
//   { label: "Medak; Telangana; ", value: "Medak; Telangana" },
//   { label: "Mewat; Haryana; ", value: "Mewat; Haryana" },
//   { label: "Miraj; Maharashtra; ", value: "Miraj; Maharashtra" },
//   { label: "Mohali; Chandigarh; ", value: "Mohali; Chandigarh" },
//   { label: "Mokokchung; Nagaland; ", value: "Mokokchung; Nagaland" },
//   { label: "Morbi; Gujarat; ", value: "Morbi; Gujarat" },
//   {
//     label: "Mumbai Subueban; Maharashtra; ",
//     value: "Mumbai Subueban; Maharashtra",
//   },
//   { label: "Mungeli; Chattisgarh; ", value: "Mungeli; Chattisgarh" },
//   { label: "Murshidabad; West Bengal; ", value: "Murshidabad; West Bengal" },
//   { label: "Mysuru; Karnataka; ", value: "Mysuru; Karnataka" },
//   { label: "Na; Arunachal Pradesh; ", value: "Na; Arunachal Pradesh" },
//   { label: "Na; Bihar; ", value: "Na; Bihar" },
//   { label: "Na; Gujarat; ", value: "Na; Gujarat" },
//   { label: "Na; Himachal Pradesh; ", value: "Na; Himachal Pradesh" },
//   { label: "Na; Maharashtra; ", value: "Na; Maharashtra" },
//   { label: "Na; Manipur; ", value: "Na; Manipur" },
//   { label: "Na; Megalaya; ", value: "Na; Megalaya" },
//   { label: "Na; Mizoram; ", value: "Na; Mizoram" },
//   { label: "Na; Nagaland; ", value: "Na; Nagaland" },
//   { label: "Na; Tripura; ", value: "Na; Tripura" },
//   { label: "Nabarangapur; Odisha; ", value: "Nabarangapur; Odisha" },
//   { label: "Nadia; West Bengal; ", value: "Nadia; West Bengal" },
//   { label: "Nagar Kurnool; Telangana; ", value: "Nagar Kurnool; Telangana" },
//   { label: "Nainital; Uttarakhand; ", value: "Nainital; Uttarakhand" },
//   { label: "Nalanda; Bihar; ", value: "Nalanda; Bihar" },
//   { label: "Nalbari; Assam; ", value: "Nalbari; Assam" },
//   { label: "Nalgonda; Telangana; ", value: "Nalgonda; Telangana" },
//   { label: "Namakkal; Tamil Nadu; ", value: "Namakkal; Tamil Nadu" },
//   { label: "Nanjangud; Karnataka; ", value: "Nanjangud; Karnataka" },
//   { label: "Narayanpur; Chattisgarh; ", value: "Narayanpur; Chattisgarh" },
//   { label: "Narmada; Gujarat; ", value: "Narmada; Gujarat" },
//   {
//     label: "Narsinghpur; Madhya Pradesh; ",
//     value: "Narsinghpur; Madhya Pradesh",
//   },
//   { label: "Nawada; Bihar; ", value: "Nawada; Bihar" },
//   { label: "Nawanshahr; Punjab; ", value: "Nawanshahr; Punjab" },
//   { label: "Nayagarh; Odisha; ", value: "Nayagarh; Odisha" },
//   {
//     label: "Nicobar; Andaman and Nico.In.; ",
//     value: "Nicobar; Andaman and Nico.In.",
//   },
//   { label: "Nilgiris; Tamil Nadu; ", value: "Nilgiris; Tamil Nadu" },
//   { label: "Nirmal; Telangana; ", value: "Nirmal; Telangana" },
//   {
//     label: "North & Middle Andaman; Andaman and Nico.In.; ",
//     value: "North & Middle Andaman; Andaman and Nico.In.",
//   },
//   {
//     label: "North 24 Parganas; West Bengal; ",
//     value: "North 24 Parganas; West Bengal",
//   },
//   {
//     label: "North And Middle Andaman; Andaman and Nico.In.; ",
//     value: "North And Middle Andaman; Andaman and Nico.In.",
//   },
//   { label: "North Delhi; Delhi; ", value: "North Delhi; Delhi" },
//   {
//     label: "North Dinajpur; West Bengal; ",
//     value: "North Dinajpur; West Bengal",
//   },
//   { label: "North East Delhi; Delhi; ", value: "North East Delhi; Delhi" },
//   { label: "North Goa; Goa; ", value: "North Goa; Goa" },
//   { label: "North Sikkim; Sikkim; ", value: "North Sikkim; Sikkim" },
//   { label: "North Sikkim; West Bengal; ", value: "North Sikkim; West Bengal" },
//   { label: "North Tripura; Tripura; ", value: "North Tripura; Tripura" },
//   { label: "North West Delhi; Delhi; ", value: "North West Delhi; Delhi" },
//   { label: "Nuapada; Odisha; ", value: "Nuapada; Odisha" },
//   { label: "Pakur; Jharkhand; ", value: "Pakur; Jharkhand" },
//   { label: "Palakol; Andhra Pradesh; ", value: "Palakol; Andhra Pradesh" },
//   { label: "Palamau; Jharkhand; ", value: "Palamau; Jharkhand" },
//   { label: "Palghar; Maharashtra; ", value: "Palghar; Maharashtra" },
//   { label: "Panch Mahals; Gujarat; ", value: "Panch Mahals; Gujarat" },
//   { label: "Panna; Madhya Pradesh; ", value: "Panna; Madhya Pradesh" },
//   {
//     label: "Papum Pare; Arunachal Pradesh; ",
//     value: "Papum Pare; Arunachal Pradesh",
//   },
//   { label: "Parkal; Telangana; ", value: "Parkal; Telangana" },
//   {
//     label: "Paschim Bardhaman; West Bengal; ",
//     value: "Paschim Bardhaman; West Bengal",
//   },
//   { label: "Pathanamthitta; Kerala; ", value: "Pathanamthitta; Kerala" },
//   {
//     label: "Pauri Garhwal; Uttarakhand; ",
//     value: "Pauri Garhwal; Uttarakhand",
//   },
//   { label: "Peddapalli; Telangana; ", value: "Peddapalli; Telangana" },
//   { label: "Perambalur; Tamil Nadu; ", value: "Perambalur; Tamil Nadu" },
//   { label: "Peren; Nagaland; ", value: "Peren; Nagaland" },
//   { label: "Phek; Nagaland; ", value: "Phek; Nagaland" },
//   { label: "Pithoragarh; Uttarakhand; ", value: "Pithoragarh; Uttarakhand" },
//   { label: "Pondicherry; Pondicherry; ", value: "Pondicherry; Pondicherry" },
//   { label: "Pondicherry; Tamil Nadu; ", value: "Pondicherry; Tamil Nadu" },
//   { label: "Poonch; Jammu and Kashmir; ", value: "Poonch; Jammu and Kashmir" },
//   { label: "Port Blair; West Bengal; ", value: "Port Blair; West Bengal" },
//   { label: "Prakasam; Andhra Pradesh; ", value: "Prakasam; Andhra Pradesh" },
//   { label: "Pratapgarh; Uttar Pradesh; ", value: "Pratapgarh; Uttar Pradesh" },
//   { label: "Pratapghar; Rajasthan; ", value: "Pratapghar; Rajasthan" },
//   {
//     label: "Prayagraj Allahabad; Uttar Pradesh; ",
//     value: "Prayagraj Allahabad; Uttar Pradesh",
//   },
//   {
//     label: "Pulwama; Jammu and Kashmir; ",
//     value: "Pulwama; Jammu and Kashmir",
//   },
//   { label: "Puri; Odisha; ", value: "Puri; Odisha" },
//   { label: "Raebareli; Uttar Pradesh; ", value: "Raebareli; Uttar Pradesh" },
//   { label: "Raigarh; Chattisgarh; ", value: "Raigarh; Chattisgarh" },
//   { label: "Raigarh; Maharashtra; ", value: "Raigarh; Maharashtra" },
//   { label: "Raigarh(Mh); Maharashtra; ", value: "Raigarh(Mh); Maharashtra" },
//   {
//     label: "Raigarh(Mh); Uttar Pradesh; ",
//     value: "Raigarh(Mh); Uttar Pradesh",
//   },
//   { label: "Raipur; Chattisgarh; ", value: "Raipur; Chattisgarh" },
//   { label: "Raisen; Madhya Pradesh; ", value: "Raisen; Madhya Pradesh" },
//   {
//     label: "Rajauri; Jammu and Kashmir; ",
//     value: "Rajauri; Jammu and Kashmir",
//   },
//   { label: "Rajgarh; Madhya Pradesh; ", value: "Rajgarh; Madhya Pradesh" },
//   { label: "Rajnandgaon; Chattisgarh; ", value: "Rajnandgaon; Chattisgarh" },
//   { label: "Rajsamand; Rajasthan; ", value: "Rajsamand; Rajasthan" },
//   { label: "Ramanagar; Karnataka; ", value: "Ramanagar; Karnataka" },
//   { label: "Ramanagara; Karnataka; ", value: "Ramanagara; Karnataka" },
//   {
//     label: "Ramanathapuram; Tamil Nadu; ",
//     value: "Ramanathapuram; Tamil Nadu",
//   },
//   { label: "Ramban; Jammu and Kashmir; ", value: "Ramban; Jammu and Kashmir" },
//   { label: "Ramdurg; Karnataka; ", value: "Ramdurg; Karnataka" },
//   { label: "Ramgarh; Jharkhand; ", value: "Ramgarh; Jharkhand" },
//   { label: "Ranga Reddy; Telangana; ", value: "Ranga Reddy; Telangana" },
//   {
//     label: "Rangareddy; Andhra Pradesh; ",
//     value: "Rangareddy; Andhra Pradesh",
//   },
//   { label: "Rangareddy; Telangana; ", value: "Rangareddy; Telangana" },
//   { label: "Ratnagiri; Maharashtra; ", value: "Ratnagiri; Maharashtra" },
//   { label: "Rayagada; Odisha; ", value: "Rayagada; Odisha" },
//   { label: "Reasi; Jammu and Kashmir; ", value: "Reasi; Jammu and Kashmir" },
//   { label: "Ri Bhoi; Megalaya; ", value: "Ri Bhoi; Megalaya" },
//   { label: "Rohtak; Jammu and Kashmir; ", value: "Rohtak; Jammu and Kashmir" },
//   { label: "Rohtas; Bihar; ", value: "Rohtas; Bihar" },
//   { label: "Ropar; Punjab; ", value: "Ropar; Punjab" },
//   { label: "Rudraprayag; Uttarakhand; ", value: "Rudraprayag; Uttarakhand" },
//   { label: "Rupnagar; Punjab; ", value: "Rupnagar; Punjab" },
//   { label: "Sabarkantha; Gujarat; ", value: "Sabarkantha; Gujarat" },
//   { label: "Sagar; Karnataka; ", value: "Sagar; Karnataka" },
//   { label: "Sahibganj; Jharkhand; ", value: "Sahibganj; Jharkhand" },
//   { label: "Saiha; Mizoram; ", value: "Saiha; Mizoram" },
//   { label: "Samastipur; Bihar; ", value: "Samastipur; Bihar" },
//   { label: "Samba; Jammu and Kashmir; ", value: "Samba; Jammu and Kashmir" },
//   { label: "Sangareddy; Telangana; ", value: "Sangareddy; Telangana" },
//   { label: "Sangli; Maharashtra; ", value: "Sangli; Maharashtra" },
//   { label: "Sangrur; Punjab; ", value: "Sangrur; Punjab" },
//   {
//     label: "Sant Kabir Nagar; Uttar Pradesh; ",
//     value: "Sant Kabir Nagar; Uttar Pradesh",
//   },
//   {
//     label: "Sant Ravidas Nagar; Uttar Pradesh; ",
//     value: "Sant Ravidas Nagar; Uttar Pradesh",
//   },
//   { label: "Saran; Bihar; ", value: "Saran; Bihar" },
//   {
//     label: "Saraswathipuram; Karnataka; ",
//     value: "Saraswathipuram; Karnataka",
//   },
//   { label: "Senapati; Manipur; ", value: "Senapati; Manipur" },
//   { label: "Sepahijala; Tripura; ", value: "Sepahijala; Tripura" },
//   {
//     label: "Seraikelakharsawan; Jharkhand; ",
//     value: "Seraikelakharsawan; Jharkhand",
//   },
//   { label: "Serchhip; Mizoram; ", value: "Serchhip; Mizoram" },
//   { label: "Shahdara; Delhi; ", value: "Shahdara; Delhi" },
//   { label: "Shahdol; Madhya Pradesh; ", value: "Shahdol; Madhya Pradesh" },
//   { label: "Shajapur; Madhya Pradesh; ", value: "Shajapur; Madhya Pradesh" },
//   { label: "Sheikhpura; Bihar; ", value: "Sheikhpura; Bihar" },
//   { label: "Sheohar; Bihar; ", value: "Sheohar; Bihar" },
//   { label: "Sheopur; Madhya Pradesh; ", value: "Sheopur; Madhya Pradesh" },
//   { label: "Shillong; Megalaya; ", value: "Shillong; Megalaya" },
//   {
//     label: "Shopian; Jammu and Kashmir; ",
//     value: "Shopian; Jammu and Kashmir",
//   },
//   { label: "Shopian; Jharkhand; ", value: "Shopian; Jharkhand" },
//   { label: "Shrawasti; Uttar Pradesh; ", value: "Shrawasti; Uttar Pradesh" },
//   { label: "Shrirampur; Maharashtra; ", value: "Shrirampur; Maharashtra" },
//   { label: "Sibsagar; Assam; ", value: "Sibsagar; Assam" },
//   { label: "Siddapur; Karnataka; ", value: "Siddapur; Karnataka" },
//   { label: "Siddharthnagar; Delhi; ", value: "Siddharthnagar; Delhi" },
//   {
//     label: "Siddharthnagar; Lakshadweep; ",
//     value: "Siddharthnagar; Lakshadweep",
//   },
//   {
//     label: "Siddharthnagar; Pondicherry; ",
//     value: "Siddharthnagar; Pondicherry",
//   },
//   {
//     label: "Siddharthnagar; Uttar Pradesh; ",
//     value: "Siddharthnagar; Uttar Pradesh",
//   },
//   {
//     label: "Siddharthnagar; West Bengal; ",
//     value: "Siddharthnagar; West Bengal",
//   },
//   { label: "Siddipet; Telangana; ", value: "Siddipet; Telangana" },
//   { label: "Sidhi; Madhya Pradesh; ", value: "Sidhi; Madhya Pradesh" },
//   { label: "Simdega; Jharkhand; ", value: "Simdega; Jharkhand" },
//   { label: "Sindhudurg; Maharashtra; ", value: "Sindhudurg; Maharashtra" },
//   { label: "Sircilla; Telangana; ", value: "Sircilla; Telangana" },
//   { label: "Sirmaur; Himachal Pradesh; ", value: "Sirmaur; Himachal Pradesh" },
//   { label: "Sirohi; Rajasthan; ", value: "Sirohi; Rajasthan" },
//   { label: "Sitamarhi; Bihar; ", value: "Sitamarhi; Bihar" },
//   { label: "Sivaganga; Tamil Nadu; ", value: "Sivaganga; Tamil Nadu" },
//   { label: "Solan; Himachal Pradesh; ", value: "Solan; Himachal Pradesh" },
//   { label: "Sonapur; Assam; ", value: "Sonapur; Assam" },
//   { label: "Sonapur; Odisha; ", value: "Sonapur; Odisha" },
//   { label: "Sonbhadra; Uttar Pradesh; ", value: "Sonbhadra; Uttar Pradesh" },
//   { label: "Sonitpur; Assam; ", value: "Sonitpur; Assam" },
//   {
//     label: "South 24 Parganas; West Bengal; ",
//     value: "South 24 Parganas; West Bengal",
//   },
//   {
//     label: "South Andaman; Andaman and Nico.In.; ",
//     value: "South Andaman; Andaman and Nico.In.",
//   },
//   { label: "South Delhi; Delhi; ", value: "South Delhi; Delhi" },
//   {
//     label: "South Dinajpur; West Bengal; ",
//     value: "South Dinajpur; West Bengal",
//   },
//   {
//     label: "South Garo Hills; Megalaya; ",
//     value: "South Garo Hills; Megalaya",
//   },
//   { label: "South Goa; Goa; ", value: "South Goa; Goa" },
//   { label: "South Sikkim; Sikkim; ", value: "South Sikkim; Sikkim" },
//   { label: "South Sikkim; West Bengal; ", value: "South Sikkim; West Bengal" },
//   { label: "South Tripura; Tripura; ", value: "South Tripura; Tripura" },
//   { label: "South West Delhi; Delhi; ", value: "South West Delhi; Delhi" },
//   { label: "South West Delhi; Haryana; ", value: "South West Delhi; Haryana" },
//   {
//     label: "South West Khasi Hills; Megalaya; ",
//     value: "South West Khasi Hills; Megalaya",
//   },
//   { label: "Sri Ganganagar; Rajasthan; ", value: "Sri Ganganagar; Rajasthan" },
//   { label: "Sriganganagar; Rajasthan; ", value: "Sriganganagar; Rajasthan" },
//   {
//     label: "Srikalahasti; Andhra Pradesh; ",
//     value: "Srikalahasti; Andhra Pradesh",
//   },
//   { label: "Stn. Jadcherla; Telangana; ", value: "Stn. Jadcherla; Telangana" },
//   { label: "Sukma; Chattisgarh; ", value: "Sukma; Chattisgarh" },
//   { label: "Sundergarh; Odisha; ", value: "Sundergarh; Odisha" },
//   { label: "Supaul; Bihar; ", value: "Supaul; Bihar" },
//   { label: "Surajpur; Chattisgarh; ", value: "Surajpur; Chattisgarh" },
//   { label: "Surendra Nagar; Gujarat; ", value: "Surendra Nagar; Gujarat" },
//   { label: "Surguja; Chattisgarh; ", value: "Surguja; Chattisgarh" },
//   { label: "Tamenglong; Manipur; ", value: "Tamenglong; Manipur" },
//   { label: "Tapi; Gujarat; ", value: "Tapi; Gujarat" },
//   { label: "Tarn Taran; Punjab; ", value: "Tarn Taran; Punjab" },
//   { label: "Tawang; Arunachal Pradesh; ", value: "Tawang; Arunachal Pradesh" },
//   {
//     label: "Tehri Garhwal; Uttarakhand; ",
//     value: "Tehri Garhwal; Uttarakhand",
//   },
//   { label: "Tengnoupal; Manipur; ", value: "Tengnoupal; Manipur" },
//   { label: "Thalassery; Kerala; ", value: "Thalassery; Kerala" },
//   { label: "Thanlon; Manipur; ", value: "Thanlon; Manipur" },
//   { label: "Theni; Tamil Nadu; ", value: "Theni; Tamil Nadu" },
//   { label: "Thoubal; Manipur; ", value: "Thoubal; Manipur" },
//   { label: "Tikamgarh; Madhya Pradesh; ", value: "Tikamgarh; Madhya Pradesh" },
//   { label: "Tinsukia; Assam; ", value: "Tinsukia; Assam" },
//   { label: "Tirap; Arunachal Pradesh; ", value: "Tirap; Arunachal Pradesh" },
//   { label: "Tiruvallur; Tamil Nadu; ", value: "Tiruvallur; Tamil Nadu" },
//   { label: "Tiruvarur; Tamil Nadu; ", value: "Tiruvarur; Tamil Nadu" },
//   { label: "Trimulgherry; Telangana; ", value: "Trimulgherry; Telangana" },
//   { label: "Tuensang; Nagaland; ", value: "Tuensang; Nagaland" },
//   { label: "Tumakuru; Karnataka; ", value: "Tumakuru; Karnataka" },
//   { label: "Tura; Megalaya; ", value: "Tura; Megalaya" },
//   { label: "Tuticorin; Tamil Nadu; ", value: "Tuticorin; Tamil Nadu" },
//   { label: "Udalguri; Assam; ", value: "Udalguri; Assam" },
//   {
//     label: "Udham Singh Nagar; Uttarakhand; ",
//     value: "Udham Singh Nagar; Uttarakhand",
//   },
//   {
//     label: "Udhampur; Jammu and Kashmir; ",
//     value: "Udhampur; Jammu and Kashmir",
//   },
//   { label: "Ukhrul; Manipur; ", value: "Ukhrul; Manipur" },
//   { label: "Umaria; Madhya Pradesh; ", value: "Umaria; Madhya Pradesh" },
//   { label: "Una; Himachal Pradesh; ", value: "Una; Himachal Pradesh" },
//   {
//     label: "Upper Dibang Valley; Arunachal Pradesh; ",
//     value: "Upper Dibang Valley; Arunachal Pradesh",
//   },
//   {
//     label: "Upper Siang; Arunachal Pradesh; ",
//     value: "Upper Siang; Arunachal Pradesh",
//   },
//   {
//     label: "Upper Subansiri; Arunachal Pradesh; ",
//     value: "Upper Subansiri; Arunachal Pradesh",
//   },
//   { label: "Uttara Kannada; Karnataka; ", value: "Uttara Kannada; Karnataka" },
//   { label: "Uttarkashi; Uttarakhand; ", value: "Uttarkashi; Uttarakhand" },
//   { label: "Vaishali; Bihar; ", value: "Vaishali; Bihar" },
//   { label: "Vikarabad; Telangana; ", value: "Vikarabad; Telangana" },
//   { label: "Villupuram; Pondicherry; ", value: "Villupuram; Pondicherry" },
//   { label: "Villupuram; Tamil Nadu; ", value: "Villupuram; Tamil Nadu" },
//   { label: "Virudhunagar; Tamil Nadu; ", value: "Virudhunagar; Tamil Nadu" },
//   { label: "Vizianagaram; Telangana; ", value: "Vizianagaram; Telangana" },
//   { label: "Wanaparthy; Telangana; ", value: "Wanaparthy; Telangana" },
//   { label: "Warangal; Andhra Pradesh; ", value: "Warangal; Andhra Pradesh" },
//   { label: "Warangal Rural; Telangana; ", value: "Warangal Rural; Telangana" },
//   { label: "Washim; Maharashtra; ", value: "Washim; Maharashtra" },
//   { label: "Wayanad; Kerala; ", value: "Wayanad; Kerala" },
//   { label: "West Champaran; Bihar; ", value: "West Champaran; Bihar" },
//   { label: "West Delhi; Delhi; ", value: "West Delhi; Delhi" },
//   { label: "West Garo Hills; Megalaya; ", value: "West Garo Hills; Megalaya" },
//   {
//     label: "West Godavari; Andhra Pradesh; ",
//     value: "West Godavari; Andhra Pradesh",
//   },
//   {
//     label: "West Godavari; Arunachal Pradesh; ",
//     value: "West Godavari; Arunachal Pradesh",
//   },
//   {
//     label: "West Jaintia Hills; Megalaya; ",
//     value: "West Jaintia Hills; Megalaya",
//   },
//   {
//     label: "West Kameng; Arunachal Pradesh; ",
//     value: "West Kameng; Arunachal Pradesh",
//   },
//   {
//     label: "West Khasi Hills; Megalaya; ",
//     value: "West Khasi Hills; Megalaya",
//   },
//   {
//     label: "West Midnapore; West Bengal; ",
//     value: "West Midnapore; West Bengal",
//   },
//   {
//     label: "West Siang; Arunachal Pradesh; ",
//     value: "West Siang; Arunachal Pradesh",
//   },
//   { label: "West Sikkim; Sikkim; ", value: "West Sikkim; Sikkim" },
//   { label: "West Singhbhum; Jharkhand; ", value: "West Singhbhum; Jharkhand" },
//   { label: "West Tripura; Manipur; ", value: "West Tripura; Manipur" },
//   { label: "West Tripura; Mizoram; ", value: "West Tripura; Mizoram" },
//   { label: "West Tripura; Tripura; ", value: "West Tripura; Tripura" },
//   { label: "Wokha; Nagaland; ", value: "Wokha; Nagaland" },
//   { label: "Yadgir; Karnataka; ", value: "Yadgir; Karnataka" },
//   { label: "Yanam; Andhra Pradesh; ", value: "Yanam; Andhra Pradesh" },
//   { label: "Zaheerabad; Telangana; ", value: "Zaheerabad; Telangana" },
//   { label: "Zunheboto; Nagaland; ", value: "Zunheboto; Nagaland" },
//   { label: "Zunhebotto; Nagaland; ", value: "Zunhebotto; Nagaland" },
// ];


export const StateList = [
  { label: "Outside India", value: "Outside India" },
  { label: "Remote", value: "Remote" },
  {
    label: "Metro Cities",
    value: "Metro Cities",
  },
  { label: "Mumbai", value: "Mumbai" },
  { label: "Delhi", value: "Delhi" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Ahmedabad", value: "Ahmedabad" },
  { label: "Chennai", value: "Chennai" },
  { label: "Kolkata", value: "Kolkata" },
  { label: "Surat", value: "Surat" },
  { label: "Pune", value: "Pune" },
  { label: "Jaipur", value: "Jaipur" },
  { label: "Lucknow", value: "Lucknow" },
  { label: "Kanpur", value: "Kanpur" },
  { label: "Nagpur", value: "Nagpur" },
  { label: "Indore", value: "Indore" },
  { label: "Thane", value: "Thane" },
  { label: "Bhopal", value: "Bhopal" },
  { label: "Visakhapatnam/Vizag", value: "Visakhapatnam/Vizag" },
  { label: "Pimpri-Chinchwad", value: "Pimpri-Chinchwad" },
  { label: "Patna", value: "Patna" },
  { label: "Vadodara", value: "Vadodara" },
  { label: "Ghaziabad", value: "Ghaziabad" },
  { label: "Ludhiana", value: "Ludhiana" },
  { label: "Agra", value: "Agra" },
  { label: "Nashik", value: "Nashik" },
  { label: "Faridabad", value: "Faridabad" },
  { label: "Meerut", value: "Meerut" },
  { label: "Rajkot", value: "Rajkot" },
  { label: "Kalyan-Dombivali", value: "Kalyan-Dombivali" },
  { label: "Vasai-Virar", value: "Vasai-Virar" },
  { label: "Varanasi", value: "Varanasi" },
  { label: "Srinagar", value: "Srinagar" },
  { label: "Aurangabad, MH", value: "Aurangabad, MH" },
  { label: "Dhanbad", value: "Dhanbad" },
  { label: "Amritsar", value: "Amritsar" },
  { label: "Navi Mumbai", value: "Navi Mumbai" },
  { label: "Prayagraj", value: "Prayagraj" },
  { label: "Howrah", value: "Howrah" },
  { label: "Ranchi", value: "Ranchi" },
  { label: "Jabalpur", value: "Jabalpur" },
  { label: "Gwalior", value: "Gwalior" },
  { label: "Coimbatore", value: "Coimbatore" },
  { label: "Vijayawada", value: "Vijayawada" },
  { label: "Jodhpur", value: "Jodhpur" },
  { label: "Madurai", value: "Madurai" },
  { label: "Raipur", value: "Raipur" },
  { label: "Kota", value: "Kota" },
  { label: "Guwahati", value: "Guwahati" },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Solapur", value: "Solapur" },
  { label: "Hubballi-Dharwad", value: "Hubballi-Dharwad" },
  { label: "Tiruchirappalli", value: "Tiruchirappalli" },
  { label: "Tiruppur", value: "Tiruppur" },
  { label: "Moradabad", value: "Moradabad" },
  { label: "Mysore", value: "Mysore" },
  { label: "Bareilly", value: "Bareilly" },
  { label: "Gurgaon", value: "Gurgaon" },
  { label: "Aligarh", value: "Aligarh" },
  { label: "Jalandhar", value: "Jalandhar" },
  { label: "Bhubaneswar", value: "Bhubaneswar" },
  { label: "Salem", value: "Salem" },
  { label: "Mira-Bhayandar", value: "Mira-Bhayandar" },
  { label: "Warangal", value: "Warangal" },
  { label: "Thiruvananthapuram", value: "Thiruvananthapuram" },
  { label: "Guntur", value: "Guntur" },
  { label: "Bhiwandi", value: "Bhiwandi" },
  { label: "Saharanpur", value: "Saharanpur" },
  { label: "Gorakhpur", value: "Gorakhpur" },
  { label: "Bikaner", value: "Bikaner" },
  { label: "Amravati", value: "Amravati" },
  { label: "Noida", value: "Noida" },
  { label: "Jamshedpur", value: "Jamshedpur" },
  { label: "Bhilai", value: "Bhilai" },
  { label: "Cuttack", value: "Cuttack" },
  { label: "Firozabad", value: "Firozabad" },
  { label: "Kochi", value: "Kochi" },
  { label: "Nellore", value: "Nellore" },
  { label: "Bhavnagar", value: "Bhavnagar" },
  { label: "Dehradun", value: "Dehradun" },
  { label: "Durgapur", value: "Durgapur" },
  { label: "Asansol", value: "Asansol" },
  { label: "Rourkela", value: "Rourkela" },
  { label: "Nanded", value: "Nanded" },
  { label: "Kolhapur", value: "Kolhapur" },
  { label: "Ajmer", value: "Ajmer" },
  { label: "Akola", value: "Akola" },
  { label: "Gulbarga", value: "Gulbarga" },
  { label: "Jamnagar", value: "Jamnagar" },
  { label: "Ujjain", value: "Ujjain" },
  { label: "Loni", value: "Loni" },
  { label: "Siliguri", value: "Siliguri" },
  { label: "Jhansi", value: "Jhansi" },
  { label: "Ulhasnagar", value: "Ulhasnagar" },
  { label: "Jammu", value: "Jammu" },
  { label: "Sangli-Miraj & Kupwad", value: "Sangli-Miraj & Kupwad" },
  { label: "Mangalore", value: "Mangalore" },
  { label: "Erode", value: "Erode" },
  { label: "Belgaum", value: "Belgaum" },
  { label: "Ambattur", value: "Ambattur" },
  { label: "Tirunelveli", value: "Tirunelveli" },
  { label: "Malegaon", value: "Malegaon" },
  { label: "Gaya", value: "Gaya" },
  { label: "Jalgaon", value: "Jalgaon" },
  { label: "Udaipur", value: "Udaipur" },
  { label: "Maheshtala", value: "Maheshtala" },
  { label: "Davanagere", value: "Davanagere" },
  { label: "Kozhikode", value: "Kozhikode" },
  { label: "Kurnool", value: "Kurnool" },
  { label: "Rajpur Sonarpur", value: "Rajpur Sonarpur" },
  { label: "Rajahmundry", value: "Rajahmundry" },
  { label: "Bokaro", value: "Bokaro" },
  { label: "South Dumdum", value: "South Dumdum" },
  { label: "Bellary", value: "Bellary" },
  { label: "Patiala", value: "Patiala" },
  { label: "Gopalpur", value: "Gopalpur" },
  { label: "Agartala", value: "Agartala" },
  { label: "Bhagalpur", value: "Bhagalpur" },
  { label: "Muzaffarnagar", value: "Muzaffarnagar" },
  { label: "Bhatpara", value: "Bhatpara" },
  { label: "Panihati", value: "Panihati" },
  { label: "Latur", value: "Latur" },
  { label: "Dhule", value: "Dhule" },
  { label: "Tirupati", value: "Tirupati" },
  { label: "Rohtak", value: "Rohtak" },
  { label: "Korba", value: "Korba" },
  { label: "Bhilwara", value: "Bhilwara" },
  { label: "Berhampur", value: "Berhampur" },
  { label: "Muzaffarpur", value: "Muzaffarpur" },
  { label: "Ahmednagar", value: "Ahmednagar" },
  { label: "Mathura", value: "Mathura" },
  { label: "Kollam", value: "Kollam" },
  { label: "Avadi", value: "Avadi" },
  { label: "Kadapa", value: "Kadapa" },
  { label: "Kamarhati", value: "Kamarhati" },
  { label: "Sambalpur", value: "Sambalpur" },
  { label: "Bilaspur", value: "Bilaspur" },
  { label: "Shahjahanpur", value: "Shahjahanpur" },
  { label: "Satara", value: "Satara" },
  { label: "Bijapur", value: "Bijapur" },
  { label: "Rampur", value: "Rampur" },
  { label: "Shivamogga", value: "Shivamogga" },
  { label: "Chandrapur", value: "Chandrapur" },
  { label: "Junagadh", value: "Junagadh" },
  { label: "Thrissur", value: "Thrissur" },
  { label: "Alwar", value: "Alwar" },
  { label: "Bardhaman", value: "Bardhaman" },
  { label: "Kulti", value: "Kulti" },
  { label: "Kakinada", value: "Kakinada" },
  { label: "Nizamabad", value: "Nizamabad" },
  { label: "Parbhani", value: "Parbhani" },
  { label: "Tumkur", value: "Tumkur" },
  { label: "Khammam", value: "Khammam" },
  { label: "Ozhukarai", value: "Ozhukarai" },
  { label: "Bihar Sharif", value: "Bihar Sharif" },
  { label: "Panipat", value: "Panipat" },
  { label: "Darbhanga", value: "Darbhanga" },
  { label: "Bally", value: "Bally" },
  { label: "Aizawl", value: "Aizawl" },
  { label: "Dewas", value: "Dewas" },
  { label: "Ichalkaranji", value: "Ichalkaranji" },
  { label: "Karnal", value: "Karnal" },
  { label: "Bathinda", value: "Bathinda" },
  { label: "Jalna", value: "Jalna" },
  { label: "Eluru", value: "Eluru" },
  { label: "Barasat", value: "Barasat" },
  { label: "Purnia", value: "Purnia" },
  { label: "Satna", value: "Satna" },
  { label: "Mau", value: "Mau" },
  { label: "Sonipat", value: "Sonipat" },
  { label: "Farrukhabad", value: "Farrukhabad" },
  { label: "Sagar", value: "Sagar" },
  { label: "Durg", value: "Durg" },
  { label: "Imphal", value: "Imphal" },
  { label: "Ratlam", value: "Ratlam" },
  { label: "Hapur", value: "Hapur" },
  { label: "Arrah", value: "Arrah" },
  { label: "Karimnagar", value: "Karimnagar" },
  { label: "Anantapur", value: "Anantapur" },
  { label: "Etawah", value: "Etawah" },
  { label: "Ambernath", value: "Ambernath" },
  { label: "North Dumdum", value: "North Dumdum" },
  { label: "Bharatpur", value: "Bharatpur" },
  { label: "Begusarai", value: "Begusarai" },
  { label: "Gandhidham", value: "Gandhidham" },
  { label: "Baranagar", value: "Baranagar" },
  { label: "Tiruvottiyur", value: "Tiruvottiyur" },
  { label: "Puducherry", value: "Puducherry" },
  { label: "Sikar", value: "Sikar" },
  { label: "Thoothukudi", value: "Thoothukudi" },
  { label: "Rewa", value: "Rewa" },
  { label: "Mirzapur", value: "Mirzapur" },
  { label: "Raichur", value: "Raichur" },
  { label: "Pali", value: "Pali" },
  { label: "Ramagundam", value: "Ramagundam" },
  { label: "Haridwar", value: "Haridwar" },
  { label: "Vijayanagaram", value: "Vijayanagaram" },
  { label: "Katihar", value: "Katihar" },
  { label: "Nagarcoil", value: "Nagarcoil" },
  { label: "Sri Ganganagar", value: "Sri Ganganagar" },
  { label: "Mango", value: "Mango" },
  { label: "Thanjavur", value: "Thanjavur" },
  { label: "Bulandshahr", value: "Bulandshahr" },
  { label: "Uluberia", value: "Uluberia" },
  { label: "Murwara", value: "Murwara" },
  { label: "Sambhal", value: "Sambhal" },
  { label: "Singrauli", value: "Singrauli" },
  { label: "Nadiad", value: "Nadiad" },
  { label: "Secunderabad", value: "Secunderabad" },
  { label: "Naihati", value: "Naihati" },
  { label: "Yamunanagar", value: "Yamunanagar" },
  { label: "Bidhan Nagar", value: "Bidhan Nagar" },
  { label: "Pallavaram", value: "Pallavaram" },
  { label: "Bidar", value: "Bidar" },
  { label: "Munger", value: "Munger" },
  { label: "Panchkula", value: "Panchkula" },
  { label: "Burhanpur", value: "Burhanpur" },
  {
    label: "Raurkela Industrial Township",
    value: "Raurkela Industrial Township",
  },
  { label: "Kharagpur", value: "Kharagpur" },
  { label: "Dindigul", value: "Dindigul" },
  { label: "Gandhinagar", value: "Gandhinagar" },
  { label: "Hospet", value: "Hospet" },
  { label: "Malda", value: "Malda" },
  { label: "Ongole", value: "Ongole" },
  { label: "Deoghar", value: "Deoghar" },
  { label: "Chapra", value: "Chapra" },
  { label: "Haldia", value: "Haldia" },
  { label: "Khandwa", value: "Khandwa" },
  { label: "Nandyal", value: "Nandyal" },
  { label: "Chittoor", value: "Chittoor" },
  { label: "Morena", value: "Morena" },
  { label: "Amroha", value: "Amroha" },
  { label: "Anand", value: "Anand" },
  { label: "Bhind", value: "Bhind" },
  { label: "Madhyamgram", value: "Madhyamgram" },
  { label: "Bhiwani", value: "Bhiwani" },
  { label: "Navi Mumbai Panvel Raigad", value: "Navi Mumbai Panvel Raigad" },
  { label: "Baharampur", value: "Baharampur" },
  { label: "Ambala", value: "Ambala" },
  { label: "Morvi", value: "Morvi" },
  { label: "Fatehpur", value: "Fatehpur" },
  { label: "Rae Bareli", value: "Rae Bareli" },
  { label: "Khora", value: "Khora" },
  { label: "Bhusawal", value: "Bhusawal" },
  { label: "Orai", value: "Orai" },
  { label: "Bahraich", value: "Bahraich" },
  { label: "Vellore", value: "Vellore" },
  { label: "Mahesana", value: "Mahesana" },
  { label: "Raiganj", value: "Raiganj" },
  { label: "Sirsa", value: "Sirsa" },
  { label: "Danapur", value: "Danapur" },
  { label: "Serampore", value: "Serampore" },
  { label: "Guna", value: "Guna" },
  { label: "Jaunpur", value: "Jaunpur" },
  { label: "Panvel", value: "Panvel" },
  { label: "Shivpuri", value: "Shivpuri" },
  { label: "Surendranagar Dudhrej", value: "Surendranagar Dudhrej" },
  { label: "Unnao", value: "Unnao" },
  { label: "Hugli and Chinsurah", value: "Hugli and Chinsurah" },
  { label: "Alappuzha", value: "Alappuzha" },
  { label: "Kottayam", value: "Kottayam" },
  { label: "Machilipatnam", value: "Machilipatnam" },
  { label: "Shimla", value: "Shimla" },
  { label: "Adoni", value: "Adoni" },
  { label: "Tenali", value: "Tenali" },
  { label: "Proddatur", value: "Proddatur" },
  { label: "Saharsa", value: "Saharsa" },
  { label: "Hindupur", value: "Hindupur" },
  { label: "Sasaram", value: "Sasaram" },
  { label: "Hajipur", value: "Hajipur" },
  { label: "Bhimavaram", value: "Bhimavaram" },
  { label: "Dehri", value: "Dehri" },
  { label: "Madanapalle", value: "Madanapalle" },
  { label: "Siwan", value: "Siwan" },
  { label: "Bettiah", value: "Bettiah" },
  { label: "Guntakal", value: "Guntakal" },
  { label: "Srikakulam", value: "Srikakulam" },
  { label: "Motihari", value: "Motihari" },
  { label: "Dharmavaram", value: "Dharmavaram" },
  { label: "Gudivada", value: "Gudivada" },
  { label: "Narasaraopet", value: "Narasaraopet" },
  { label: "Bagaha", value: "Bagaha" },
  { label: "Miryalaguda", value: "Miryalaguda" },
  { label: "Tadipatri", value: "Tadipatri" },
  { label: "Kishanganj", value: "Kishanganj" },
  { label: "Karaikudi", value: "Karaikudi" },
  { label: "Suryapet", value: "Suryapet" },
  { label: "Jamalpur", value: "Jamalpur" },
  { label: "Kavali", value: "Kavali" },
  { label: "Tadepalligudem", value: "Tadepalligudem" },
  { label: "Amaravati", value: "Amaravati" },
  { label: "Buxar", value: "Buxar" },
  { label: "Jehanabad", value: "Jehanabad" },
  { label: "Aurangabad, Bihar", value: "Aurangabad, Bihar" },
  { label: "Mon", value: "Mon" },
  { label: "Adilabad", value: "Adilabad" },
  { label: "Agar Malwa", value: "Agar Malwa" },
  { label: "Agartala ", value: "Agartala " },
  { label: "Ahmed Nagar", value: "Ahmed Nagar" },
  { label: "Ahmedabad City", value: "Ahmedabad City" },
  { label: "Aizawal", value: "Aizawal" },
  { label: "Alirajpur", value: "Alirajpur" },
  { label: "Allahabad", value: "Allahabad" },
  { label: "Almora", value: "Almora" },
  { label: "Ambala City", value: "Ambala City" },
  { label: "Ambedkar Nagar", value: "Ambedkar Nagar" },
  { label: "Amethi", value: "Amethi" },
  { label: "Amlapuram", value: "Amlapuram" },
  { label: "Amreli", value: "Amreli" },
  { label: "Ananthapur", value: "Ananthapur" },
  { label: "Ananthnag", value: "Ananthnag" },
  { label: "Angul", value: "Angul" },
  { label: "Anuppur", value: "Anuppur" },
  { label: "Araria", value: "Araria" },
  { label: "Aravalli", value: "Aravalli" },
  { label: "Ariyalur", value: "Ariyalur" },
  { label: "Arsikere", value: "Arsikere" },
  { label: "Arwal", value: "Arwal" },
  { label: "Ashok Nagar", value: "Ashok Nagar" },
  { label: "Athani", value: "Athani" },
  { label: "Auraiya", value: "Auraiya" },
  { label: "Aurangabad, UP", value: "Aurangabad, UP" },
  { label: "Avanigadda", value: "Avanigadda" },
  { label: "Azamgarh", value: "Azamgarh" },
  { label: "Bagalkot", value: "Bagalkot" },
  { label: "Bageshwar", value: "Bageshwar" },
  { label: "Bagpat", value: "Bagpat" },
  { label: "Bailhongal", value: "Bailhongal" },
  { label: "Baksa", value: "Baksa" },
  { label: "Balaghat", value: "Balaghat" },
  { label: "Balangir", value: "Balangir" },
  { label: "Balasore", value: "Balasore" },
  { label: "Baleswar", value: "Baleswar" },
  { label: "Ballari", value: "Ballari" },
  { label: "Ballia, UP", value: "Ballia, UP" },
  { label: "Ballia, Bihar", value: "Ballia, Bihar" },
  { label: "Balod", value: "Balod" },
  { label: "Balod Bazer", value: "Balod Bazer" },
  { label: "Balrampur, CG", value: "Balrampur, CG" },
  { label: "Balrampur, UP", value: "Balrampur, UP" },
  { label: "Banaskantha", value: "Banaskantha" },
  { label: "Banda", value: "Banda" },
  { label: "Bandipur", value: "Bandipur" },
  { label: "Banka, Bihar", value: "Banka, Bihar" },
  { label: "Banka, GJ", value: "Banka, GJ" },
  { label: "Bankura", value: "Bankura" },
  { label: "Banswara", value: "Banswara" },
  { label: "Bapatla", value: "Bapatla" },
  { label: "Barabanki", value: "Barabanki" },
  { label: "Baramulla", value: "Baramulla" },
  { label: "Baran", value: "Baran" },
  { label: "Bargarh", value: "Bargarh" },
  { label: "Barmer", value: "Barmer" },
  { label: "Barnala", value: "Barnala" },
  { label: "Barpeta", value: "Barpeta" },
  { label: "Barwani", value: "Barwani" },
  { label: "Bastar", value: "Bastar" },
  { label: "Basti, UP", value: "Basti, UP" },
  { label: "Beed", value: "Beed" },
  { label: "Belagavi", value: "Belagavi" },
  { label: "Bemetara", value: "Bemetara" },
  { label: "Bengaluru", value: "Bengaluru" },
  { label: "Bengaluru Rural", value: "Bengaluru Rural" },
  { label: "Betul", value: "Betul" },
  { label: "Bhadrak", value: "Bhadrak" },
  { label: "Bhandara", value: "Bhandara" },
  { label: "Bharuch", value: "Bharuch" },
  { label: "Bhojpur", value: "Bhojpur" },
  { label: "Bhupalpalli", value: "Bhupalpalli" },
  { label: "Bijapur(Cgh)", value: "Bijapur(Cgh)" },
  { label: "Bijapur(Kar)", value: "Bijapur(Kar)" },
  { label: "Bijnor", value: "Bijnor" },
  { label: "Bilaspur (Hp)", value: "Bilaspur (Hp)" },
  { label: "Bilaspur Hp", value: "Bilaspur Hp" },
  { label: "Bilaspurcgh", value: "Bilaspurcgh" },
  { label: "Birbhum", value: "Birbhum" },
  { label: "Bishnupur", value: "Bishnupur" },
  { label: "Bongaigaon", value: "Bongaigaon" },
  { label: "Botab", value: "Botab" },
  { label: "Boudh", value: "Boudh" },
  { label: "Budaun", value: "Budaun" },
  { label: "Budgam", value: "Budgam" },
  { label: "Buldhana", value: "Buldhana" },
  { label: "Bundi", value: "Bundi" },
  { label: "Cachar", value: "Cachar" },
  { label: "Chamarajanagar", value: "Chamarajanagar" },
  { label: "Chamba", value: "Chamba" },
  { label: "Chamoli", value: "Chamoli" },
  { label: "Champawat", value: "Champawat" },
  { label: "Champhai", value: "Champhai" },
  { label: "Chamrajnagar", value: "Chamrajnagar" },
  { label: "Chandauli", value: "Chandauli" },
  { label: "Chandel", value: "Chandel" },
  { label: "Changlang", value: "Changlang" },
  { label: "Chatra", value: "Chatra" },
  { label: "Chhatarpur", value: "Chhatarpur" },
  { label: "Chhindwara", value: "Chhindwara" },
  { label: "Chhota Udepur", value: "Chhota Udepur" },
  { label: "Chhotaudepur", value: "Chhotaudepur" },
  { label: "Chickmagalur", value: "Chickmagalur" },
  { label: "Chikkaballapur", value: "Chikkaballapur" },
  { label: "Chikkamagaluru", value: "Chikkamagaluru" },
  { label: "Chitradurga", value: "Chitradurga" },
  { label: "Chitrakoot", value: "Chitrakoot" },
  { label: "Chittorgarh", value: "Chittorgarh" },
  { label: "Churachandpur", value: "Churachandpur" },
  { label: "Churu", value: "Churu" },
  { label: "Cooch Behar", value: "Cooch Behar" },
  { label: "Cuddalore", value: "Cuddalore" },
  { label: "Dadra  Nagar Haveli", value: "Dadra  Nagar Haveli" },
  { label: "Dahod", value: "Dahod" },
  { label: "Dakshina Kannada", value: "Dakshina Kannada" },
  { label: "Daman", value: "Daman" },
  { label: "Damoh", value: "Damoh" },
  { label: "Dangs", value: "Dangs" },
  { label: "Dantewada", value: "Dantewada" },
  { label: "Darjeeling", value: "Darjeeling" },
  { label: "Darjiling", value: "Darjiling" },
  { label: "Darrang", value: "Darrang" },
  { label: "Datia", value: "Datia" },
  { label: "Dausa", value: "Dausa" },
  { label: "Davangere", value: "Davangere" },
  { label: "Debagarh", value: "Debagarh" },
  { label: "Deoria", value: "Deoria" },
  { label: "Devbhoomi Dwerka", value: "Devbhoomi Dwerka" },
  { label: "Dhalai", value: "Dhalai" },
  { label: "Dhamtari", value: "Dhamtari" },
  { label: "Dhar", value: "Dhar" },
  { label: "Dharmanagar", value: "Dharmanagar" },
  { label: "Dharmapuri", value: "Dharmapuri" },
  { label: "Dharwad", value: "Dharwad" },
  { label: "Dhemaji", value: "Dhemaji" },
  { label: "Dhenkanal", value: "Dhenkanal" },
  { label: "Dholpur", value: "Dholpur" },
  { label: "Dhubri", value: "Dhubri" },
  { label: "Dibang Valley", value: "Dibang Valley" },
  { label: "Dibrugarh", value: "Dibrugarh" },
  {
    label: "Dima Hasso - North Cachar Hill",
    value: "Dima Hasso - North Cachar Hill",
  },
  { label: "Dindori", value: "Dindori" },
  { label: "Diu", value: "Diu" },
  { label: "Doda", value: "Doda" },
  { label: "Dumka", value: "Dumka" },
  { label: "Dungarpur", value: "Dungarpur" },
  { label: "East Champaran", value: "East Champaran" },
  { label: "East Delhi", value: "East Delhi" },
  { label: "East Garo Hills", value: "East Garo Hills" },
  { label: "East Godavari", value: "East Godavari" },
  { label: "East Jaintia Hills", value: "East Jaintia Hills" },
  { label: "East Kameng", value: "East Kameng" },
  { label: "East Khasi Hills", value: "East Khasi Hills" },
  { label: "East Midnapore", value: "East Midnapore" },
  { label: "East Siang", value: "East Siang" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "East Singhbhum", value: "East Singhbhum" },
  { label: "Ernakulam", value: "Ernakulam" },
  { label: "Etah", value: "Etah" },
  { label: "Faizabad", value: "Faizabad" },
  { label: "Faridkot", value: "Faridkot" },
  { label: "Fatehabad", value: "Fatehabad" },
  { label: "Fatehgarh Sahib", value: "Fatehgarh Sahib" },
  { label: "Fazilka", value: "Fazilka" },
  { label: "Ferozepur", value: "Ferozepur" },
  { label: "Firozpur", value: "Firozpur" },
  { label: "Gadag", value: "Gadag" },
  { label: "Gadchiroli", value: "Gadchiroli" },
  { label: "Gadwal", value: "Gadwal" },
  { label: "Gajapati", value: "Gajapati" },
  { label: "Ganderbal", value: "Ganderbal" },
  { label: "Gandhi Nagar", value: "Gandhi Nagar" },
  { label: "Ganjam", value: "Ganjam" },
  { label: "Garhwa", value: "Garhwa" },
  { label: "Gariaband", value: "Gariaband" },
  { label: "Gautam Buddha Nagar", value: "Gautam Buddha Nagar" },
  { label: "Ghazipur", value: "Ghazipur" },
  { label: "Gir Somnath", value: "Gir Somnath" },
  { label: "Giridh", value: "Giridh" },
  { label: "Goalpara", value: "Goalpara" },
  { label: "Godda", value: "Godda" },
  { label: "Golaghat", value: "Golaghat" },
  { label: "Gomati", value: "Gomati" },
  { label: "Gomti", value: "Gomti" },
  { label: "Gonda", value: "Gonda" },
  { label: "Gondia", value: "Gondia" },
  { label: "Gopalganj", value: "Gopalganj" },
  { label: "Gumla", value: "Gumla" },
  { label: "Gurdaspur", value: "Gurdaspur" },
  { label: "Hailakandi", value: "Hailakandi" },
  { label: "Hamirpur", value: "Hamirpur" },
  { label: "Hamirpur(Hp)", value: "Hamirpur(Hp)" },
  { label: "Hamirpurhp", value: "Hamirpurhp" },
  { label: "Hanagodu", value: "Hanagodu" },
  { label: "Hanamkonda", value: "Hanamkonda" },
  { label: "Hanumangarh", value: "Hanumangarh" },
  { label: "Harda", value: "Harda" },
  { label: "Hardoi", value: "Hardoi" },
  { label: "Hassan", value: "Hassan" },
  { label: "Hathras", value: "Hathras" },
  { label: "Haveri", value: "Haveri" },
  { label: "Hazaribagh", value: "Hazaribagh" },
  { label: "Hingoli", value: "Hingoli" },
  { label: "Hisar", value: "Hisar" },
  { label: "Hooghly", value: "Hooghly" },
  { label: "Hoshangabad", value: "Hoshangabad" },
  { label: "Hoshiarpur", value: "Hoshiarpur" },
  { label: "Hubballi", value: "Hubballi" },
  { label: "Hunsur", value: "Hunsur" },
  { label: "Idukki", value: "Idukki" },
  { label: "Imphal East", value: "Imphal East" },
  { label: "Imphal West", value: "Imphal West" },
  { label: "Jagatsinghapur", value: "Jagatsinghapur" },
  { label: "Jagdalpur", value: "Jagdalpur" },
  { label: "Jagtial", value: "Jagtial" },
  { label: "Jaintia Hills", value: "Jaintia Hills" },
  { label: "Jaisalmer", value: "Jaisalmer" },
  { label: "Jajapur", value: "Jajapur" },
  { label: "Jalaun", value: "Jalaun" },
  { label: "Jalgoan", value: "Jalgoan" },
  { label: "Jalor", value: "Jalor" },
  { label: "Jalpaiguri", value: "Jalpaiguri" },
  { label: "Jamkhandi", value: "Jamkhandi" },
  { label: "Jamtara", value: "Jamtara" },
  { label: "Jamui", value: "Jamui" },
  { label: "Jangaon", value: "Jangaon" },
  { label: "Janjgirchampa", value: "Janjgirchampa" },
  { label: "Janjgir-Champa", value: "Janjgir-Champa" },
  { label: "Jaspur", value: "Jaspur" },
  { label: "Jayashankar", value: "Jayashankar" },
  { label: "Jhabua", value: "Jhabua" },
  { label: "Jhalawar", value: "Jhalawar" },
  { label: "Jharsuguda", value: "Jharsuguda" },
  { label: "Jhujhunu", value: "Jhujhunu" },
  { label: "Jind", value: "Jind" },
  { label: "Jiribam", value: "Jiribam" },
  { label: "Joida", value: "Joida" },
  { label: "Jorhat", value: "Jorhat" },
  { label: "K.V.Rangareddy", value: "K.V.Rangareddy" },
  { label: "Kachchh", value: "Kachchh" },
  { label: "Kaimur Bhabua", value: "Kaimur Bhabua" },
  { label: "Kaithal", value: "Kaithal" },
  { label: "Kalaburagi", value: "Kalaburagi" },
  { label: "Kalahandi", value: "Kalahandi" },
  { label: "Kalghatgi", value: "Kalghatgi" },
  { label: "Kalimpong", value: "Kalimpong" },
  { label: "Kamareddy", value: "Kamareddy" },
  { label: "Kamrup", value: "Kamrup" },
  { label: "Kamrup Metropolitan", value: "Kamrup Metropolitan" },
  { label: "Kanchipuram", value: "Kanchipuram" },
  { label: "Kandhamal", value: "Kandhamal" },
  { label: "Kangra", value: "Kangra" },
  { label: "Kanker", value: "Kanker" },
  { label: "Kannauj", value: "Kannauj" },
  { label: "Kannur", value: "Kannur" },
  { label: "Kanpur Dehat", value: "Kanpur Dehat" },
  { label: "Kanpur Nagar", value: "Kanpur Nagar" },
  { label: "Kanshiram Nagar", value: "Kanshiram Nagar" },
  { label: "Kanyakumari", value: "Kanyakumari" },
  { label: "Kapurthala", value: "Kapurthala" },
  { label: "Karaikal", value: "Karaikal" },
  { label: "Karauli", value: "Karauli" },
  { label: "Karbi Anglong", value: "Karbi Anglong" },
  { label: "Kargil", value: "Kargil" },
  { label: "Karimganj", value: "Karimganj" },
  { label: "Karur", value: "Karur" },
  { label: "Kasaragod", value: "Kasaragod" },
  { label: "Kasargod", value: "Kasargod" },
  { label: "Kathua", value: "Kathua" },
  { label: "Katni", value: "Katni" },
  { label: "Kaushambi", value: "Kaushambi" },
  { label: "Kawardha", value: "Kawardha" },
  { label: "Kendrapara", value: "Kendrapara" },
  { label: "Kendujhar", value: "Kendujhar" },
  { label: "Khagaria", value: "Khagaria" },
  { label: "Khargone", value: "Khargone" },
  { label: "Kheda", value: "Kheda" },
  { label: "Kheri", value: "Kheri" },
  { label: "Khorda", value: "Khorda" },
  { label: "Khowai", value: "Khowai" },
  { label: "Khunti", value: "Khunti" },
  { label: "Kinnaur", value: "Kinnaur" },
  { label: "Kiphire", value: "Kiphire" },
  { label: "Kodagu", value: "Kodagu" },
  { label: "Kohima", value: "Kohima" },
  { label: "Kokrajhar", value: "Kokrajhar" },
  { label: "Kolar", value: "Kolar" },
  { label: "Kolasib", value: "Kolasib" },
  { label: "Kondagaon", value: "Kondagaon" },
  { label: "Koppal", value: "Koppal" },
  { label: "Koraput", value: "Koraput" },
  { label: "Koriya", value: "Koriya" },
  { label: "Kothagudem", value: "Kothagudem" },
  { label: "Kothagudem Colls", value: "Kothagudem Colls" },
  { label: "Kovvur", value: "Kovvur" },
  { label: "Krishna", value: "Krishna" },
  { label: "Krishnagiri", value: "Krishnagiri" },
  { label: "Kulgam", value: "Kulgam" },
  { label: "Kullu", value: "Kullu" },
  { label: "Kundgol", value: "Kundgol" },
  { label: "Kupwara", value: "Kupwara" },
  { label: "Kurukshetra", value: "Kurukshetra" },
  { label: "Kurung Kumey", value: "Kurung Kumey" },
  { label: "Kushinagar", value: "Kushinagar" },
  { label: "Lahul  Spiti", value: "Lahul  Spiti" },
  { label: "Lakhimpur", value: "Lakhimpur" },
  { label: "Lakhisarai", value: "Lakhisarai" },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Lalitpur", value: "Lalitpur" },
  { label: "Latehar", value: "Latehar" },
  { label: "Lawngtlai", value: "Lawngtlai" },
  { label: "Leh", value: "Leh" },
  { label: "Lohardaga", value: "Lohardaga" },
  { label: "Lohit", value: "Lohit" },
  { label: "Longleng", value: "Longleng" },
  { label: "Lower Dibang Valley", value: "Lower Dibang Valley" },
  { label: "Lower Subansiri", value: "Lower Subansiri" },
  { label: "Lunglei", value: "Lunglei" },
  { label: "Madhepura", value: "Madhepura" },
  { label: "Madhubani", value: "Madhubani" },
  { label: "Madikeri", value: "Madikeri" },
  { label: "Mahabuababad", value: "Mahabuababad" },
  { label: "Mahabubabad", value: "Mahabubabad" },
  { label: "Maharajganj", value: "Maharajganj" },
  { label: "Mahasamund", value: "Mahasamund" },
  { label: "Mahe", value: "Mahe" },
  { label: "Mahendragarh", value: "Mahendragarh" },
  { label: "Mahisagar", value: "Mahisagar" },
  { label: "Mahoba", value: "Mahoba" },
  { label: "Mahrajganj", value: "Mahrajganj" },
  { label: "Mainpuri", value: "Mainpuri" },
  { label: "Makdi", value: "Makdi" },
  { label: "Malappuram", value: "Malappuram" },
  { label: "Malappurm", value: "Malappurm" },
  { label: "Malkangiri", value: "Malkangiri" },
  { label: "Mamit", value: "Mamit" },
  { label: "Mammit", value: "Mammit" },
  { label: "Mancherial", value: "Mancherial" },
  { label: "Mandi", value: "Mandi" },
  { label: "Mandla", value: "Mandla" },
  { label: "Mandsaur", value: "Mandsaur" },
  { label: "Mandya", value: "Mandya" },
  { label: "Mansa", value: "Mansa" },
  { label: "Marigaon", value: "Marigaon" },
  { label: "Mayurbhanj", value: "Mayurbhanj" },
  { label: "Medak", value: "Medak" },
  { label: "Medinipur", value: "Medinipur" },
  { label: "Mewat", value: "Mewat" },
  { label: "Miraj", value: "Miraj" },
  { label: "Moga", value: "Moga" },
  { label: "Mokokchung", value: "Mokokchung" },
  { label: "Morbi", value: "Morbi" },
  { label: "Muktsar", value: "Muktsar" },
  { label: "Mumbai Subueban", value: "Mumbai Subueban" },
  { label: "Mungeli", value: "Mungeli" },
  { label: "Murshidabad", value: "Murshidabad" },
  { label: "Mysuru", value: "Mysuru" },
  { label: "Nabarangapur", value: "Nabarangapur" },
  { label: "Nadia", value: "Nadia" },
  { label: "Nagaon", value: "Nagaon" },
  { label: "Nagapattinam", value: "Nagapattinam" },
  { label: "Nagar Kurnool", value: "Nagar Kurnool" },
  { label: "Nagaur", value: "Nagaur" },
  { label: "Nainital", value: "Nainital" },
  { label: "Nalanda", value: "Nalanda" },
  { label: "Nalbari", value: "Nalbari" },
  { label: "Nalgonda", value: "Nalgonda" },
  { label: "Namakkal", value: "Namakkal" },
  { label: "Nandurbar", value: "Nandurbar" },
  { label: "Nanjangud", value: "Nanjangud" },
  { label: "Narayanpur", value: "Narayanpur" },
  { label: "Narmada", value: "Narmada" },
  { label: "Narsinghpur", value: "Narsinghpur" },
  { label: "Navsari", value: "Navsari" },
  { label: "Nawada", value: "Nawada" },
  { label: "Nawanshahr", value: "Nawanshahr" },
  { label: "Nayagarh", value: "Nayagarh" },
  { label: "Neemuch", value: "Neemuch" },
  { label: "New Delhi", value: "New Delhi" },
  { label: "Nicobar", value: "Nicobar" },
  { label: "Nilgiris", value: "Nilgiris" },
  { label: "Nirmal", value: "Nirmal" },
  { label: "North & Middle Andaman", value: "North & Middle Andaman" },
  { label: "North 24 Parganas", value: "North 24 Parganas" },
  { label: "North And Middle Andaman", value: "North And Middle Andaman" },
  { label: "North Delhi", value: "North Delhi" },
  { label: "North Dinajpur", value: "North Dinajpur" },
  { label: "North East Delhi", value: "North East Delhi" },
  { label: "North Goa", value: "North Goa" },
  { label: "North Tripura", value: "North Tripura" },
  { label: "North West Delhi", value: "North West Delhi" },
  { label: "Nuapada", value: "Nuapada" },
  { label: "Osmanabad", value: "Osmanabad" },
  { label: "Pakur", value: "Pakur" },
  { label: "Palakkad", value: "Palakkad" },
  { label: "Palakol", value: "Palakol" },
  { label: "Palamau", value: "Palamau" },
  { label: "Palghar", value: "Palghar" },
  { label: "Palwal", value: "Palwal" },
  { label: "Panch Mahals", value: "Panch Mahals" },
  { label: "Panna", value: "Panna" },
  { label: "Papum Pare", value: "Papum Pare" },
  { label: "Parkal", value: "Parkal" },
  { label: "Paschim Bardhaman", value: "Paschim Bardhaman" },
  { label: "Patan", value: "Patan" },
  { label: "Pathanamthitta", value: "Pathanamthitta" },
  { label: "Pathankot", value: "Pathankot" },
  { label: "Pauri Garhwal", value: "Pauri Garhwal" },
  { label: "Peddapalli", value: "Peddapalli" },
  { label: "Perambalur", value: "Perambalur" },
  { label: "Peren", value: "Peren" },
  { label: "Phek", value: "Phek" },
  { label: "Pilibhit", value: "Pilibhit" },
  { label: "Pithoragarh", value: "Pithoragarh" },
  { label: "Poonch", value: "Poonch" },
  { label: "Porbandar", value: "Porbandar" },
  { label: "Port Blair", value: "Port Blair" },
  { label: "Prakasam", value: "Prakasam" },
  { label: "Pratapgarh", value: "Pratapgarh" },
  { label: "Pratapghar", value: "Pratapghar" },
  { label: "Prayagraj Allahabad", value: "Prayagraj Allahabad" },
  { label: "Pudukkottai", value: "Pudukkottai" },
  { label: "Pulwama", value: "Pulwama" },
  { label: "Puri", value: "Puri" },
  { label: "Puruliya", value: "Puruliya" },
  { label: "Raebareli", value: "Raebareli" },
  { label: "Raisen", value: "Raisen" },
  { label: "Rajauri", value: "Rajauri" },
  { label: "Rajgarh", value: "Rajgarh" },
  { label: "Rajnandgaon", value: "Rajnandgaon" },
  { label: "Rajsamand", value: "Rajsamand" },
  { label: "Ramanagar", value: "Ramanagar" },
  { label: "Ramanagara", value: "Ramanagara" },
  { label: "Ramanathapuram", value: "Ramanathapuram" },
  { label: "Ramban", value: "Ramban" },
  { label: "Ramdurg", value: "Ramdurg" },
  { label: "Ramgarh", value: "Ramgarh" },
  { label: "Ranga Reddy", value: "Ranga Reddy" },
  { label: "Ratnagiri", value: "Ratnagiri" },
  { label: "Rayagada", value: "Rayagada" },
  { label: "Reasi", value: "Reasi" },
  { label: "Rewari", value: "Rewari" },
  { label: "Ri Bhoi", value: "Ri Bhoi" },
  { label: "Rohtas", value: "Rohtas" },
  { label: "Ropar", value: "Ropar" },
  { label: "Rudraprayag", value: "Rudraprayag" },
  { label: "Rupnagar", value: "Rupnagar" },
  { label: "Sabarkantha", value: "Sabarkantha" },
  { label: "Sahibganj", value: "Sahibganj" },
  { label: "Saiha", value: "Saiha" },
  { label: "Samastipur", value: "Samastipur" },
  { label: "Samba", value: "Samba" },
  { label: "Sangareddy", value: "Sangareddy" },
  { label: "Sangli", value: "Sangli" },
  { label: "Sangrur", value: "Sangrur" },
  { label: "Sant Kabir Nagar", value: "Sant Kabir Nagar" },
  { label: "Sant Ravidas Nagar", value: "Sant Ravidas Nagar" },
  { label: "Saran", value: "Saran" },
  { label: "Saraswathipuram", value: "Saraswathipuram" },
  { label: "Sawai Madhopur", value: "Sawai Madhopur" },
  { label: "Sehore", value: "Sehore" },
  { label: "Senapati", value: "Senapati" },
  { label: "Seoni", value: "Seoni" },
  { label: "Sepahijala", value: "Sepahijala" },
  { label: "Seraikelakharsawan", value: "Seraikelakharsawan" },
  { label: "Serchhip", value: "Serchhip" },
  { label: "Shahdara", value: "Shahdara" },
  { label: "Shahdol", value: "Shahdol" },
  { label: "Shajapur", value: "Shajapur" },
  { label: "Sheikhpura", value: "Sheikhpura" },
  { label: "Sheohar", value: "Sheohar" },
  { label: "Sheopur", value: "Sheopur" },
  { label: "Shillong", value: "Shillong" },
  { label: "Shrawasti", value: "Shrawasti" },
  { label: "Shrirampur", value: "Shrirampur" },
  { label: "Sibsagar", value: "Sibsagar" },
  { label: "Siddapur", value: "Siddapur" },
  { label: "Siddipet", value: "Siddipet" },
  { label: "Sidhi", value: "Sidhi" },
  { label: "Simdega", value: "Simdega" },
  { label: "Sindhudurg", value: "Sindhudurg" },
  { label: "Sircilla", value: "Sircilla" },
  { label: "Sirmaur", value: "Sirmaur" },
  { label: "Sirohi", value: "Sirohi" },
  { label: "Sitamarhi", value: "Sitamarhi" },
  { label: "Sitapur", value: "Sitapur" },
  { label: "Sivaganga", value: "Sivaganga" },
  { label: "Solan", value: "Solan" },
  { label: "Sonbhadra", value: "Sonbhadra" },
  { label: "Sonitpur", value: "Sonitpur" },
  { label: "South 24 Parganas", value: "South 24 Parganas" },
  { label: "South Andaman", value: "South Andaman" },
  { label: "South Delhi", value: "South Delhi" },
  { label: "South Dinajpur", value: "South Dinajpur" },
  { label: "South Garo Hills", value: "South Garo Hills" },
  { label: "South Goa", value: "South Goa" },
  { label: "South Tripura", value: "South Tripura" },
  { label: "South West Khasi Hills", value: "South West Khasi Hills" },
  { label: "Sriganganagar", value: "Sriganganagar" },
  { label: "Srikalahasti", value: "Srikalahasti" },
  { label: "Stn. Jadcherla", value: "Stn. Jadcherla" },
  { label: "Sukma", value: "Sukma" },
  { label: "Sultanpur", value: "Sultanpur" },
  { label: "Sundergarh", value: "Sundergarh" },
  { label: "Supaul", value: "Supaul" },
  { label: "Surajpur", value: "Surajpur" },
  { label: "Surendra Nagar", value: "Surendra Nagar" },
  { label: "Surguja", value: "Surguja" },
  { label: "Tamenglong", value: "Tamenglong" },
  { label: "Tapi", value: "Tapi" },
  { label: "Tarn Taran", value: "Tarn Taran" },
  { label: "Tawang", value: "Tawang" },
  { label: "Tehri Garhwal", value: "Tehri Garhwal" },
  { label: "Tengnoupal", value: "Tengnoupal" },
  { label: "Thalassery", value: "Thalassery" },
  { label: "Thanlon", value: "Thanlon" },
  { label: "Theni", value: "Theni" },
  { label: "Thoubal", value: "Thoubal" },
  { label: "Tikamgarh", value: "Tikamgarh" },
  { label: "Tinsukia", value: "Tinsukia" },
  { label: "Tirap", value: "Tirap" },
  { label: "Tiruvallur", value: "Tiruvallur" },
  { label: "Tiruvannamalai", value: "Tiruvannamalai" },
  { label: "Tiruvarur", value: "Tiruvarur" },
  { label: "Tonk", value: "Tonk" },
  { label: "Trimulgherry", value: "Trimulgherry" },
  { label: "Tuensang", value: "Tuensang" },
  { label: "Tumakuru", value: "Tumakuru" },
  { label: "Tura", value: "Tura" },
  { label: "Tuticorin", value: "Tuticorin" },
  { label: "Udalguri", value: "Udalguri" },
  { label: "Udham Singh Nagar", value: "Udham Singh Nagar" },
  { label: "Udhampur", value: "Udhampur" },
  { label: "Udupi", value: "Udupi" },
  { label: "Ukhrul", value: "Ukhrul" },
  { label: "Umaria", value: "Umaria" },
  { label: "Una", value: "Una" },
  { label: "Upper Dibang Valley", value: "Upper Dibang Valley" },
  { label: "Upper Siang", value: "Upper Siang" },
  { label: "Upper Subansiri", value: "Upper Subansiri" },
  { label: "Uttara Kannada", value: "Uttara Kannada" },
  { label: "Uttarkashi", value: "Uttarkashi" },
  { label: "Vaishali", value: "Vaishali" },
  { label: "Valsad", value: "Valsad" },
  { label: "Vidisha", value: "Vidisha" },
  { label: "Vikarabad", value: "Vikarabad" },
  { label: "Virudhunagar", value: "Virudhunagar" },
  { label: "Visakhapatnam", value: "Visakhapatnam" },
  { label: "Wanaparthy", value: "Wanaparthy" },
  { label: "Warangal Rural", value: "Warangal Rural" },
  { label: "Wardha", value: "Wardha" },
  { label: "Washim", value: "Washim" },
  { label: "Wayanad", value: "Wayanad" },
  { label: "West Champaran", value: "West Champaran" },
  { label: "West Delhi", value: "West Delhi" },
  { label: "West Garo Hills", value: "West Garo Hills" },
  { label: "West Godavari", value: "West Godavari" },
  { label: "West Jaintia Hills", value: "West Jaintia Hills" },
  { label: "West Kameng", value: "West Kameng" },
  { label: "West Khasi Hills", value: "West Khasi Hills" },
  { label: "West Midnapore", value: "West Midnapore" },
  { label: "West Siang", value: "West Siang" },
  { label: "West Sikkim", value: "West Sikkim" },
  { label: "West Singhbhum", value: "West Singhbhum" },
  { label: "West Tripura", value: "West Tripura" },
  { label: "Wokha", value: "Wokha" },
  { label: "Yadgir", value: "Yadgir" },
  { label: "Yanam", value: "Yanam" },
  { label: "Yavatmal", value: "Yavatmal" },
  { label: "Zaheerabad", value: "Zaheerabad" },
  { label: "Zunheboto", value: "Zunheboto" },
  { label: "Zunhebotto", value: "Zunhebotto" },
  { label: "Prayagraj/Allahabad", value: "Prayagraj/Allahabad" },
  { label: "Hubli-Dharwad", value: "Hubli-Dharwad" },
  { label: "Kirari Suleman Nagar", value: "Kirari Suleman Nagar" },
  { label: "Nagercoil", value: "Nagercoil" },
  { label: "Ganganagar", value: "Ganganagar" },
  { label: "Karawal Nagar", value: "Karawal Nagar" },
  { label: "English Bazar", value: "English Bazar" },
  { label: "Puri Town", value: "Puri Town" },
  { label: "Bhalswa Jahangir Pur", value: "Bhalswa Jahangir Pur" },
  { label: "Dinapur Nizamat", value: "Dinapur Nizamat" },
  { label: "Sultan Pur Majra", value: "Sultan Pur Majra" },
  { label: "Hastsal", value: "Hastsal" },
  { label: "Tambaram", value: "Tambaram" },
  { label: "Adityapur", value: "Adityapur" },
  { label: "Badalapur", value: "Badalapur" },
  { label: "Silchar", value: "Silchar" },
  { label: "Gadag and Betigeri", value: "Gadag and Betigeri" },
  { label: "Bahadurgarh", value: "Bahadurgarh" },
  { label: "Deoli", value: "Deoli" },
  { label: "Chandannagar", value: "Chandannagar" },
  { label: "Alandur", value: "Alandur" },
  { label: "Kancheepuram", value: "Kancheepuram" },
  { label: "Vapi", value: "Vapi" },
  { label: "Uttarpara Kotrung", value: "Uttarpara Kotrung" },
  { label: "Mahbubnagar", value: "Mahbubnagar" },
  { label: "Batala", value: "Batala" },
  { label: "Haldwani and Kathgodam", value: "Haldwani and Kathgodam" },
  { label: "Thanesar", value: "Thanesar" },
  { label: "Kishangarh", value: "Kishangarh" },
  { label: "Dallo Pura", value: "Dallo Pura" },
  { label: "Veraval", value: "Veraval" },
  { label: "Krishnanagar", value: "Krishnanagar" },
  { label: "Barrackpur", value: "Barrackpur" },
  { label: "Santipur", value: "Santipur" },
  { label: "Balurghat", value: "Balurghat" },
  { label: "Bhadravati", value: "Bhadravati" },
  { label: "Habra", value: "Habra" },
  { label: "Bid", value: "Bid" },
  { label: "Burari", value: "Burari" },
  { label: "Beawar", value: "Beawar" },
  { label: "Abohar", value: "Abohar" },
  { label: "Jamuria", value: "Jamuria" },
  { label: "Godhra", value: "Godhra" },
  { label: "Bhuj", value: "Bhuj" },
  { label: "Robertson Pet", value: "Robertson Pet" },
  { label: "Chas", value: "Chas" },
  { label: "Rudrapur", value: "Rudrapur" },
  { label: "Kumbakonam", value: "Kumbakonam" },
  { label: "Malerkotla", value: "Malerkotla" },
  { label: "Chhattarpur", value: "Chhattarpur" },
  { label: "Gondiya", value: "Gondiya" },
  { label: "North Barrackpur", value: "North Barrackpur" },
  { label: "Rajapalayam", value: "Rajapalayam" },
  { label: "Botad", value: "Botad" },
  { label: "Modinagar", value: "Modinagar" },
  { label: "Raniganj", value: "Raniganj" },
  { label: "Khanna", value: "Khanna" },
  { label: "Mustafabad", value: "Mustafabad" },
  { label: "Pithampur", value: "Pithampur" },
  { label: "Dhaulpur", value: "Dhaulpur" },
  { label: "Nabadwip", value: "Nabadwip" },
  { label: "Basirhat", value: "Basirhat" },
  { label: "Halisahar", value: "Halisahar" },
  { label: "Jagadhri", value: "Jagadhri" },
  { label: "Rishra", value: "Rishra" },
  { label: "Kurichi", value: "Kurichi" },
  { label: "Palanpur", value: "Palanpur" },
  { label: "Gokal Pur", value: "Gokal Pur" },
  { label: "Kashipur", value: "Kashipur" },
  { label: "Ashokenagar Kalyangarh", value: "Ashokenagar Kalyangarh" },
  { label: "Baidyabati", value: "Baidyabati" },
  { label: "Mandoli", value: "Mandoli" },
  { label: "Kanchrapara", value: "Kanchrapara" },
  { label: "Madavaram", value: "Madavaram" },
  { label: "Gangapur", value: "Gangapur" },
  { label: "Dabgram", value: "Dabgram" },
  { label: "Barshi", value: "Barshi" },
  { label: "Jhunjhunun", value: "Jhunjhunun" },
  { label: "Chikmagalur", value: "Chikmagalur" },
  { label: "Jetpur Navagadh", value: "Jetpur Navagadh" },
  { label: "Roorkee", value: "Roorkee" },
  { label: "Baleshwar", value: "Baleshwar" },
  { label: "Hosur", value: "Hosur" },
  { label: "Titagarh", value: "Titagarh" },
  { label: "Chittaurgarh", value: "Chittaurgarh" },
  { label: "Dum Dum", value: "Dum Dum" },
  { label: "Ambur", value: "Ambur" },
  { label: "Giridih", value: "Giridih" },
  { label: "Chandausi", value: "Chandausi" },
  { label: "Bally Town", value: "Bally Town" },
  { label: "Kalol", value: "Kalol" },
  { label: "Ambikapur", value: "Ambikapur" },
  { label: "Achalpur", value: "Achalpur" },
  { label: "Gondal", value: "Gondal" },
  { label: "Akbarpur", value: "Akbarpur" },
  { label: "Champdani", value: "Champdani" },
  { label: "Deesa", value: "Deesa" },
  { label: "Khurja", value: "Khurja" },
  { label: "Delhi Cantonment", value: "Delhi Cantonment" },
  { label: "Baripada", value: "Baripada" },
  { label: "Mughalsarai", value: "Mughalsarai" },
  { label: "Anantnag", value: "Anantnag" },
  { label: "Bongaon", value: "Bongaon" },
  { label: "Kanpur Cantonment", value: "Kanpur Cantonment" },
  { label: "Khardaha", value: "Khardaha" },
  { label: "Tadpatri", value: "Tadpatri" },
  { label: "Andaman and Nicobar", value: "Andaman and Nicobar" },
  { label: "Shikohabad", value: "Shikohabad" },
  { label: "Shamli", value: "Shamli" },
  { label: "Karaikkudi", value: "Karaikkudi" },
  { label: "Ranibennur", value: "Ranibennur" },
  { label: "Neyveli", value: "Neyveli" },
  { label: "Gangawati", value: "Gangawati" },
  { label: "Hindaun", value: "Hindaun" },
  { label: "Bhiwadi", value: "Bhiwadi" },
  { label: "Bansberia", value: "Bansberia" },
  { label: "Baraut", value: "Baraut" },
  { label: "Udgir", value: "Udgir" },
  { label: "Ambala Sadar", value: "Ambala Sadar" },
  { label: "Greater Noida", value: "Greater Noida" },
  { label: "Hinganghat", value: "Hinganghat" },
  { label: "Sujangarh", value: "Sujangarh" },
  { label: "Bhadreswar", value: "Bhadreswar" },
  { label: "Chilakaluripet", value: "Chilakaluripet" },
  { label: "Kasganj", value: "Kasganj" },
  { label: "Kalyani", value: "Kalyani" },
  { label: "Gangtok", value: "Gangtok" },
  { label: "Nagda", value: "Nagda" },
];
export const LocationList = [
  { label: "Mumbai; Maharashtra", value: "Mumbai; Maharashtra" },
  { label: "Delhi; Delhi", value: "Delhi; Delhi" },
  { label: "Bangalore; Karnataka", value: "Bangalore; Karnataka" },
  { label: "Hyderabad; Telangana", value: "Hyderabad; Telangana" },
  { label: "Ahmedabad; Gujarat", value: "Ahmedabad; Gujarat" },
  { label: "Chennai; Tamil Nadu", value: "Chennai; Tamil Nadu" },
  { label: "Kolkata; West Bengal", value: "Kolkata; West Bengal" },
  { label: "Surat; Gujarat", value: "Surat; Gujarat" },
  { label: "Pune; Maharashtra", value: "Pune; Maharashtra" },
  { label: "Jaipur; Rajasthan", value: "Jaipur; Rajasthan" },
  {
    label: "Visakhapatnam; Andhra Pradesh",
    value: "Visakhapatnam; Andhra Pradesh",
  },
  { label: "Kanpur; Uttar Pradesh", value: "Kanpur; Uttar Pradesh" },
  { label: "Nagpur; Maharashtra", value: "Nagpur; Maharashtra" },
  { label: "Lucknow; Uttar Pradesh", value: "Lucknow; Uttar Pradesh" },
  { label: "Thane; Maharashtra", value: "Thane; Maharashtra" },
  { label: "Bhopal; Madhya Pradesh", value: "Bhopal; Madhya Pradesh" },
  { label: "Indore; Madhya Pradesh", value: "Indore; Madhya Pradesh" },
  {
    label: "Pimpri-Chinchwad; Maharashtra",
    value: "Pimpri-Chinchwad; Maharashtra",
  },
  { label: "Patna; Bihar", value: "Patna; Bihar" },
  { label: "Vadodara; Gujarat", value: "Vadodara; Gujarat" },
  { label: "Ghaziabad; Uttar Pradesh", value: "Ghaziabad; Uttar Pradesh" },
  { label: "Ludhiana; Punjab", value: "Ludhiana; Punjab" },
  { label: "Agra; Uttar Pradesh", value: "Agra; Uttar Pradesh" },
  { label: "Nashik; Maharashtra", value: "Nashik; Maharashtra" },
  { label: "Faridabad; Haryana", value: "Faridabad; Haryana" },
  { label: "Meerut; Uttar Pradesh", value: "Meerut; Uttar Pradesh" },
  { label: "Rajkot; Gujarat", value: "Rajkot; Gujarat" },
  {
    label: "Kalyan-Dombivli; Maharashtra",
    value: "Kalyan-Dombivli; Maharashtra",
  },
  { label: "Vasai-Virar; Maharashtra", value: "Vasai-Virar; Maharashtra" },
  { label: "Varanasi; Uttar Pradesh", value: "Varanasi; Uttar Pradesh" },
  {
    label: "Srinagar; Jammu and Kashmir",
    value: "Srinagar; Jammu and Kashmir",
  },
  { label: "Aurangabad; Maharashtra", value: "Aurangabad; Maharashtra" },
  { label: "Dhanbad; Jharkhand", value: "Dhanbad; Jharkhand" },
  { label: "Amritsar; Punjab", value: "Amritsar; Punjab" },
  { label: "Navi Mumbai; Maharashtra", value: "Navi Mumbai; Maharashtra" },
  {
    label: "Allahabad; Uttar Pradesh",
    value: "Allahabad; Uttar Pradesh",
  },
  { label: "Howrah; West Bengal", value: "Howrah; West Bengal" },
  { label: "Ranchi; Jharkhand", value: "Ranchi; Jharkhand" },
  { label: "Gwalior; Madhya Pradesh", value: "Gwalior; Madhya Pradesh" },
  { label: "Jabalpur; Madhya Pradesh", value: "Jabalpur; Madhya Pradesh" },
  { label: "Coimbatore; Tamil Nadu", value: "Coimbatore; Tamil Nadu" },
  { label: "Vijayawada; Andhra Pradesh", value: "Vijayawada; Andhra Pradesh" },
  { label: "Jodhpur; Rajasthan", value: "Jodhpur; Rajasthan" },
  { label: "Madurai; Tamil Nadu", value: "Madurai; Tamil Nadu" },
  { label: "Raipur; Chhattisgarh", value: "Raipur; Chhattisgarh" },
  { label: "Chandigarh; Chandigarh", value: "Chandigarh; Chandigarh" },
  { label: "Guntur; Andhra Pradesh", value: "Guntur; Andhra Pradesh" },
  { label: "Guwahati; Assam", value: "Guwahati; Assam" },
  { label: "Solapur; Maharashtra", value: "Solapur; Maharashtra" },
  { label: "Hubli–Dharwad; Karnataka", value: "Hubli–Dharwad; Karnataka" },
  {
    label: "Tiruchirappalli; Tamil Nadu",
    value: "Tiruchirappalli; Tamil Nadu",
  },
  { label: "Bareilly; Uttar Pradesh", value: "Bareilly; Uttar Pradesh" },
  { label: "Moradabad; Uttar Pradesh", value: "Moradabad; Uttar Pradesh" },
  { label: "Mysore; Karnataka", value: "Mysore; Karnataka" },
  { label: "Tiruppur; Tamil Nadu", value: "Tiruppur; Tamil Nadu" },
  { label: "Gurgaon; Haryana", value: "Gurgaon; Haryana" },
  { label: "Aligarh; Uttar Pradesh", value: "Aligarh; Uttar Pradesh" },
  { label: "Jalandhar; Punjab", value: "Jalandhar; Punjab" },
  { label: "Bhubaneswar; Odisha", value: "Bhubaneswar; Odisha" },
  { label: "Salem; Tamil Nadu", value: "Salem; Tamil Nadu" },
  {
    label: "Mira-Bhayandar; Maharashtra",
    value: "Mira-Bhayandar; Maharashtra",
  },
  { label: "Warangal; Telangana", value: "Warangal; Telangana" },
  { label: "Jalgaon; Maharashtra", value: "Jalgaon; Maharashtra" },
  { label: "Kota; Rajasthan", value: "Kota; Rajasthan" },
  { label: "Bhiwandi; Maharashtra", value: "Bhiwandi; Maharashtra" },
  { label: "Saharanpur; Uttar Pradesh", value: "Saharanpur; Uttar Pradesh" },
  { label: "Gorakhpur; Uttar Pradesh", value: "Gorakhpur; Uttar Pradesh" },
  { label: "Bikaner; Rajasthan", value: "Bikaner; Rajasthan" },
  { label: "Amravati; Maharashtra", value: "Amravati; Maharashtra" },
  { label: "Noida; Uttar Pradesh", value: "Noida; Uttar Pradesh" },
  { label: "Jamshedpur; Jharkhand", value: "Jamshedpur; Jharkhand" },
  { label: "Bhilai; Chhattisgarh", value: "Bhilai; Chhattisgarh" },
  { label: "Cuttack; Odisha", value: "Cuttack; Odisha" },
  { label: "Firozabad; Uttar Pradesh", value: "Firozabad; Uttar Pradesh" },
  { label: "Kochi; Kerala", value: "Kochi; Kerala" },
  { label: "Nellore; Andhra Pradesh", value: "Nellore; Andhra Pradesh" },
  { label: "Bhavnagar; Gujarat", value: "Bhavnagar; Gujarat" },
  { label: "Dehradun; Uttarakhand", value: "Dehradun; Uttarakhand" },
  { label: "Durgapur; West Bengal", value: "Durgapur; West Bengal" },
  { label: "Asansol; West Bengal", value: "Asansol; West Bengal" },
  { label: "Rourkela; Odisha", value: "Rourkela; Odisha" },
  { label: "Nanded; Maharashtra", value: "Nanded; Maharashtra" },
  { label: "Kolhapur; Maharashtra", value: "Kolhapur; Maharashtra" },
  { label: "Ajmer; Rajasthan", value: "Ajmer; Rajasthan" },
  { label: "Akola; Maharashtra", value: "Akola; Maharashtra" },
  { label: "Gulbarga; Karnataka", value: "Gulbarga; Karnataka" },
  { label: "Jamnagar; Gujarat", value: "Jamnagar; Gujarat" },
  { label: "Ujjain; Madhya Pradesh", value: "Ujjain; Madhya Pradesh" },
  { label: "Loni; Uttar Pradesh", value: "Loni; Uttar Pradesh" },
  { label: "Siliguri; West Bengal", value: "Siliguri; West Bengal" },
  { label: "Jhansi; Uttar Pradesh", value: "Jhansi; Uttar Pradesh" },
  { label: "Ulhasnagar; Maharashtra", value: "Ulhasnagar; Maharashtra" },
  { label: "Jammu; Jammu and Kashmir", value: "Jammu; Jammu and Kashmir" },
  {
    label: "Sangli-Miraj & Kupwad; Maharashtra",
    value: "Sangli-Miraj & Kupwad; Maharashtra",
  },
  { label: "Mangalore; Karnataka", value: "Mangalore; Karnataka" },
  { label: "Erode; Tamil Nadu", value: "Erode; Tamil Nadu" },
  { label: "Belgaum; Karnataka", value: "Belgaum; Karnataka" },
  { label: "Ambattur; Tamil Nadu", value: "Ambattur; Tamil Nadu" },
  { label: "Tirunelveli; Tamil Nadu", value: "Tirunelveli; Tamil Nadu" },
  { label: "Malegaon; Maharashtra", value: "Malegaon; Maharashtra" },
  { label: "Gaya; Bihar", value: "Gaya; Bihar" },
  { label: "Thiruvananthapuram; Kerala", value: "Thiruvananthapuram; Kerala" },
  { label: "Udaipur; Rajasthan", value: "Udaipur; Rajasthan" },
  { label: "Kakinada; Andhra Pradesh", value: "Kakinada; Andhra Pradesh" },
  { label: "Davanagere; Karnataka", value: "Davanagere; Karnataka" },
  { label: "Kozhikode; Kerala", value: "Kozhikode; Kerala" },
  { label: "Maheshtala; West Bengal", value: "Maheshtala; West Bengal" },
  {
    label: "Rajpur Sonarpur; West Bengal",
    value: "Rajpur Sonarpur; West Bengal",
  },
  {
    label: "Rajahmundry; Andhra Pradesh",
    value: "Rajahmundry; Andhra Pradesh",
  },
  { label: "Bokaro; Jharkhand", value: "Bokaro; Jharkhand" },
  { label: "South Dumdum; West Bengal", value: "South Dumdum; West Bengal" },
  { label: "Bellary; Karnataka", value: "Bellary; Karnataka" },
  { label: "Patiala; Punjab", value: "Patiala; Punjab" },
  { label: "Gopalpur; West Bengal", value: "Gopalpur; West Bengal" },
  { label: "Agartala; Tripura", value: "Agartala; Tripura" },
  { label: "Bhagalpur; Bihar", value: "Bhagalpur; Bihar" },
  {
    label: "Muzaffarnagar; Uttar Pradesh",
    value: "Muzaffarnagar; Uttar Pradesh",
  },
  { label: "Bhatpara; West Bengal", value: "Bhatpara; West Bengal" },
  { label: "Panihati; West Bengal", value: "Panihati; West Bengal" },
  { label: "Latur; Maharashtra", value: "Latur; Maharashtra" },
  { label: "Dhule; Maharashtra", value: "Dhule; Maharashtra" },
  { label: "Tirupati; Andhra Pradesh", value: "Tirupati; Andhra Pradesh" },
  { label: "Rohtak; Haryana", value: "Rohtak; Haryana" },
  { label: "Korba; Chhattisgarh", value: "Korba; Chhattisgarh" },
  { label: "Bhilwara; Rajasthan", value: "Bhilwara; Rajasthan" },
  { label: "Berhampur; Odisha", value: "Berhampur; Odisha" },
  { label: "Muzaffarpur; Bihar", value: "Muzaffarpur; Bihar" },
  { label: "Ahmednagar; Maharashtra", value: "Ahmednagar; Maharashtra" },
  { label: "Mathura; Uttar Pradesh", value: "Mathura; Uttar Pradesh" },
  { label: "Kollam; Kerala", value: "Kollam; Kerala" },
  { label: "Avadi; Tamil Nadu", value: "Avadi; Tamil Nadu" },
  { label: "Kadapa; Andhra Pradesh", value: "Kadapa; Andhra Pradesh" },
  { label: "Kamarhati; West Bengal", value: "Kamarhati; West Bengal" },
  { label: "Sambalpur; Odisha", value: "Sambalpur; Odisha" },
  { label: "Bilaspur; Chhattisgarh", value: "Bilaspur; Chhattisgarh" },
  {
    label: "Shahjahanpur; Uttar Pradesh",
    value: "Shahjahanpur; Uttar Pradesh",
  },
  { label: "Satara; Maharashtra", value: "Satara; Maharashtra" },
  { label: "Bijapur; Karnataka", value: "Bijapur; Karnataka" },
  { label: "Kurnool; Andhra Pradesh", value: "Kurnool; Andhra Pradesh" },
  { label: "Rampur; Uttar Pradesh", value: "Rampur; Uttar Pradesh" },
  { label: "Shimoga; Karnataka", value: "Shimoga; Karnataka" },
  { label: "Chandrapur; Maharashtra", value: "Chandrapur; Maharashtra" },
  { label: "Junagadh; Gujarat", value: "Junagadh; Gujarat" },
  { label: "Thrissur; Kerala", value: "Thrissur; Kerala" },
  { label: "Alwar; Rajasthan", value: "Alwar; Rajasthan" },
  { label: "Bardhaman; West Bengal", value: "Bardhaman; West Bengal" },
  { label: "Kulti; West Bengal", value: "Kulti; West Bengal" },
  { label: "Nizamabad; Telangana", value: "Nizamabad; Telangana" },
  { label: "Parbhani; Maharashtra", value: "Parbhani; Maharashtra" },
  { label: "Tumkur; Karnataka", value: "Tumkur; Karnataka" },
  { label: "Khammam; Telangana", value: "Khammam; Telangana" },
  { label: "Ozhukarai; Puducherry", value: "Ozhukarai; Puducherry" },
  { label: "Bihar Sharif; Bihar", value: "Bihar Sharif; Bihar" },
  { label: "Panipat; Haryana", value: "Panipat; Haryana" },
  { label: "Darbhanga; Bihar", value: "Darbhanga; Bihar" },
  { label: "Bally; West Bengal", value: "Bally; West Bengal" },
  { label: "Aizawl; Mizoram", value: "Aizawl; Mizoram" },
  { label: "Dewas; Madhya Pradesh", value: "Dewas; Madhya Pradesh" },
  { label: "Ichalkaranji; Maharashtra", value: "Ichalkaranji; Maharashtra" },
  { label: "Karnal; Haryana", value: "Karnal; Haryana" },
  { label: "Bathinda; Punjab", value: "Bathinda; Punjab" },
  { label: "Jalna; Maharashtra", value: "Jalna; Maharashtra" },
  { label: "Eluru; Andhra Pradesh", value: "Eluru; Andhra Pradesh" },
  { label: "Barasat; West Bengal", value: "Barasat; West Bengal" },
  {
    label: "Kirari Suleman Nagar; Delhi",
    value: "Kirari Suleman Nagar; Delhi",
  },
  { label: "Purnia; Bihar", value: "Purnia; Bihar" },
  { label: "Satna; Madhya Pradesh", value: "Satna; Madhya Pradesh" },
  { label: "Mau; Uttar Pradesh", value: "Mau; Uttar Pradesh" },
  { label: "Sonipat; Haryana", value: "Sonipat; Haryana" },
  { label: "Farrukhabad; Uttar Pradesh", value: "Farrukhabad; Uttar Pradesh" },
  { label: "Sagar; Madhya Pradesh", value: "Sagar; Madhya Pradesh" },
  { label: "Durg; Chhattisgarh", value: "Durg; Chhattisgarh" },
  { label: "Imphal; Manipur", value: "Imphal; Manipur" },
  { label: "Ratlam; Madhya Pradesh", value: "Ratlam; Madhya Pradesh" },
  { label: "Hapur; Uttar Pradesh", value: "Hapur; Uttar Pradesh" },
  { label: "Arrah; Bihar", value: "Arrah; Bihar" },
  { label: "Anantapur; Andhra Pradesh", value: "Anantapur; Andhra Pradesh" },
  { label: "Karimnagar; Telangana", value: "Karimnagar; Telangana" },
  { label: "Etawah; Uttar Pradesh", value: "Etawah; Uttar Pradesh" },
  { label: "Ambarnath; Maharashtra", value: "Ambarnath; Maharashtra" },
  { label: "North Dumdum; West Bengal", value: "North Dumdum; West Bengal" },
  { label: "Bharatpur; Rajasthan", value: "Bharatpur; Rajasthan" },
  { label: "Begusarai; Bihar", value: "Begusarai; Bihar" },
  { label: "New Delhi; Delhi", value: "New Delhi; Delhi" },
  { label: "Gandhidham; Gujarat", value: "Gandhidham; Gujarat" },
  { label: "Baranagar; West Bengal", value: "Baranagar; West Bengal" },
  { label: "Tiruvottiyur; Tamil Nadu", value: "Tiruvottiyur; Tamil Nadu" },
  { label: "Pondicherry; Puducherry", value: "Pondicherry; Puducherry" },
  { label: "Sikar; Rajasthan", value: "Sikar; Rajasthan" },
  { label: "Thoothukudi; Tamil Nadu", value: "Thoothukudi; Tamil Nadu" },
  { label: "Rewa; Madhya Pradesh", value: "Rewa; Madhya Pradesh" },
  { label: "Mirzapur; Uttar Pradesh", value: "Mirzapur; Uttar Pradesh" },
  { label: "Raichur; Karnataka", value: "Raichur; Karnataka" },
  { label: "Pali; Rajasthan", value: "Pali; Rajasthan" },
  { label: "Ramagundam; Telangana", value: "Ramagundam; Telangana" },
  { label: "Haridwar; Uttarakhand", value: "Haridwar; Uttarakhand" },
  {
    label: "Vijayanagaram; Andhra Pradesh",
    value: "Vijayanagaram; Andhra Pradesh",
  },
  { label: "Tenali; Andhra Pradesh", value: "Tenali; Andhra Pradesh" },
  { label: "Nagercoil; Tamil Nadu", value: "Nagercoil; Tamil Nadu" },
  { label: "Sri Ganganagar; Rajasthan", value: "Sri Ganganagar; Rajasthan" },
  { label: "Karawal Nagar; Delhi", value: "Karawal Nagar; Delhi" },
  { label: "Mango; Jharkhand", value: "Mango; Jharkhand" },
  { label: "Thanjavur; Tamil Nadu", value: "Thanjavur; Tamil Nadu" },
  { label: "Bulandshahr; Uttar Pradesh", value: "Bulandshahr; Uttar Pradesh" },
  { label: "Uluberia; West Bengal", value: "Uluberia; West Bengal" },
  { label: "Katni; Madhya Pradesh", value: "Katni; Madhya Pradesh" },
  { label: "Sambhal; Uttar Pradesh", value: "Sambhal; Uttar Pradesh" },
  { label: "Singrauli; Madhya Pradesh", value: "Singrauli; Madhya Pradesh" },
  { label: "Nadiad; Gujarat", value: "Nadiad; Gujarat" },
  { label: "Secunderabad; Telangana", value: "Secunderabad; Telangana" },
  { label: "Naihati; West Bengal", value: "Naihati; West Bengal" },
  { label: "Yamunanagar; Haryana", value: "Yamunanagar; Haryana" },
  { label: "Bidhannagar; West Bengal", value: "Bidhannagar; West Bengal" },
  { label: "Pallavaram; Tamil Nadu", value: "Pallavaram; Tamil Nadu" },
  { label: "Bidar; Karnataka", value: "Bidar; Karnataka" },
  { label: "Munger; Bihar", value: "Munger; Bihar" },
  { label: "Panchkula; Haryana", value: "Panchkula; Haryana" },
  { label: "Burhanpur; Madhya Pradesh", value: "Burhanpur; Madhya Pradesh" },
  {
    label: "Raurkela Industrial Township; Odisha",
    value: "Raurkela Industrial Township; Odisha",
  },
  { label: "Kharagpur; West Bengal", value: "Kharagpur; West Bengal" },
  { label: "Dindigul; Tamil Nadu", value: "Dindigul; Tamil Nadu" },
  { label: "Gandhinagar; Gujarat", value: "Gandhinagar; Gujarat" },
  { label: "Hospet; Karnataka", value: "Hospet; Karnataka" },
  { label: "Nangloi Jat; Delhi", value: "Nangloi Jat; Delhi" },
  { label: "Malda; West Bengal", value: "Malda; West Bengal" },
  { label: "Ongole; Andhra Pradesh", value: "Ongole; Andhra Pradesh" },
  { label: "Deoghar; Jharkhand", value: "Deoghar; Jharkhand" },
  { label: "Chapra; Bihar", value: "Chapra; Bihar" },
  { label: "Haldia; West Bengal", value: "Haldia; West Bengal" },
  { label: "Khandwa; Madhya Pradesh", value: "Khandwa; Madhya Pradesh" },
  { label: "Nandyal; Andhra Pradesh", value: "Nandyal; Andhra Pradesh" },
  { label: "Morena; Madhya Pradesh", value: "Morena; Madhya Pradesh" },
  { label: "Amroha; Uttar Pradesh", value: "Amroha; Uttar Pradesh" },
  { label: "Anand; Gujarat", value: "Anand; Gujarat" },
  { label: "Bhind; Madhya Pradesh", value: "Bhind; Madhya Pradesh" },
  {
    label: "Bhalswa Jahangir Pur; Delhi",
    value: "Bhalswa Jahangir Pur; Delhi",
  },
  { label: "Madhyamgram; West Bengal", value: "Madhyamgram; West Bengal" },
  { label: "Bhiwani; Haryana", value: "Bhiwani; Haryana" },
  { label: "Berhampore; West Bengal", value: "Berhampore; West Bengal" },
  { label: "Ambala; Haryana", value: "Ambala; Haryana" },
  { label: "Morbi; Gujarat", value: "Morbi; Gujarat" },
  { label: "Fatehpur; Uttar Pradesh", value: "Fatehpur; Uttar Pradesh" },
  { label: "Raebareli; Uttar Pradesh", value: "Raebareli; Uttar Pradesh" },
  { label: "Mahaboobnagar; Telangana", value: "Mahaboobnagar; Telangana" },
  { label: "Chittoor; Andhra Pradesh", value: "Chittoor; Andhra Pradesh" },
  { label: "Bhusawal; Maharashtra", value: "Bhusawal; Maharashtra" },
  { label: "Orai; Uttar Pradesh", value: "Orai; Uttar Pradesh" },
  { label: "Bahraich; Uttar Pradesh", value: "Bahraich; Uttar Pradesh" },
  { label: "Vellore; Tamil Nadu", value: "Vellore; Tamil Nadu" },
  { label: "Mehsana; Gujarat", value: "Mehsana; Gujarat" },
  { label: "Raiganj; West Bengal", value: "Raiganj; West Bengal" },
  { label: "Sirsa; Haryana", value: "Sirsa; Haryana" },
  { label: "Danapur; Bihar", value: "Danapur; Bihar" },
  { label: "Serampore; West Bengal", value: "Serampore; West Bengal" },
  { label: "Sultan Pur Majra; Delhi", value: "Sultan Pur Majra; Delhi" },
  { label: "Guna; Madhya Pradesh", value: "Guna; Madhya Pradesh" },
  { label: "Jaunpur; Uttar Pradesh", value: "Jaunpur; Uttar Pradesh" },
  { label: "Panvel; Maharashtra", value: "Panvel; Maharashtra" },
  { label: "Shivpuri; Madhya Pradesh", value: "Shivpuri; Madhya Pradesh" },
  {
    label: "Surendranagar Dudhrej; Gujarat",
    value: "Surendranagar Dudhrej; Gujarat",
  },
  { label: "Unnao; Uttar Pradesh", value: "Unnao; Uttar Pradesh" },
  { label: "Chinsurah; West Bengal", value: "Chinsurah; West Bengal" },
  { label: "Alappuzha; Kerala", value: "Alappuzha; Kerala" },
  { label: "Kottayam; Kerala", value: "Kottayam; Kerala" },
  {
    label: "Machilipatnam; Andhra Pradesh",
    value: "Machilipatnam; Andhra Pradesh",
  },
  { label: "Shimla; Himachal Pradesh", value: "Shimla; Himachal Pradesh" },
  { label: "Adoni; Andhra Pradesh", value: "Adoni; Andhra Pradesh" },
  { label: "Udupi; Karnataka", value: "Udupi; Karnataka" },
  { label: "Katihar; Bihar", value: "Katihar; Bihar" },
  { label: "Proddatur; Andhra Pradesh", value: "Proddatur; Andhra Pradesh" },
  { label: "Saharsa; Bihar", value: "Saharsa; Bihar" },
  { label: "Hindupur; Andhra Pradesh", value: "Hindupur; Andhra Pradesh" },
  { label: "Sasaram; Bihar", value: "Sasaram; Bihar" },
  { label: "Hajipur; Bihar", value: "Hajipur; Bihar" },
  { label: "Bhimavaram; Andhra Pradesh", value: "Bhimavaram; Andhra Pradesh" },
  { label: "Kumbakonam; Tamil Nadu", value: "Kumbakonam; Tamil Nadu" },
  { label: "Dehri; Bihar", value: "Dehri; Bihar" },
  {
    label: "Madanapalle; Andhra Pradesh",
    value: "Madanapalle; Andhra Pradesh",
  },
  { label: "Siwan; Bihar", value: "Siwan; Bihar" },
  { label: "Bettiah; Bihar", value: "Bettiah; Bihar" },
  { label: "Guntakal; Andhra Pradesh", value: "Guntakal; Andhra Pradesh" },
  { label: "Srikakulam; Andhra Pradesh", value: "Srikakulam; Andhra Pradesh" },
  { label: "Motihari; Bihar", value: "Motihari; Bihar" },
  {
    label: "Dharmavaram; Andhra Pradesh",
    value: "Dharmavaram; Andhra Pradesh",
  },
  { label: "Gudivada; Andhra Pradesh", value: "Gudivada; Andhra Pradesh" },
  { label: "Phagwara; Punjab", value: "Phagwara; Punjab" },
  {
    label: "Narasaraopet; Andhra Pradesh",
    value: "Narasaraopet; Andhra Pradesh",
  },
  { label: "Suryapet; Telangana", value: "Suryapet; Telangana" },
  { label: "Miryalaguda; Telangana", value: "Miryalaguda; Telangana" },
  { label: "Tadipatri; Andhra Pradesh", value: "Tadipatri; Andhra Pradesh" },
  { label: "Karaikudi; Tamil Nadu", value: "Karaikudi; Tamil Nadu" },
  { label: "Kishanganj; Bihar", value: "Kishanganj; Bihar" },
  { label: "Jamalpur; Bihar", value: "Jamalpur; Bihar" },
  { label: "Ballia; Uttar Pradesh", value: "Ballia; Uttar Pradesh" },
  { label: "Kavali; Andhra Pradesh", value: "Kavali; Andhra Pradesh" },
  {
    label: "Tadepalligudem; Andhra Pradesh",
    value: "Tadepalligudem; Andhra Pradesh",
  },
  { label: "Amaravati; Andhra Pradesh", value: "Amaravati; Andhra Pradesh" },
  { label: "Buxar; Bihar", value: "Buxar; Bihar" },
  { label: "Jehanabad; Bihar", value: "Jehanabad; Bihar" },
  { label: "Aurangabad; Bihar", value: "Aurangabad; Bihar" },
  { label: "Gangtok; Sikkim", value: "Gangtok; Sikkim" },
];

export const QualificationFilter = [
  { label: "Bachelor's degree", value: "Bachelor's degree" },
  { label: "Master's degree", value: "Master's degree" },
  { label: "Diploma", value: "Diploma" },
  { label: "12th Pass", value: "12th Pass" },
  { label: "10th Pass", value: "10th Pass" },
  { label: "M.Phil", value: "M.Phil" },
];

export const ststusList = [
  { label: "Shortlisted", value: "Shortlisted" },
  { label: "Applied", value: "Applied" },
  { label: "Rejected", value: "Rejected" },
];

export const YearsOfExperience = [
  // { label: "All Exp. level", value: "All Exp. level" },
  { label: "1 to 3 years", value: "1 to 3 years" },
  { label: "4 to 6 years", value: "4 to 6 years" },
  { label: "7 to 10 years", value: "7 to 10 years" },
  { label: "11 to 15 years", value: "11 to 15 years" },
  { label: "15+ years", value: "15+ years" },
];

export const ReferralBonus = [
  { label: "10k", value: "10K" },
  { label: "20k", value: "20K" },
  { label: "30k", value: "30K" },
  { label: "40k", value: "40K" },
  { label: "50k", value: "50K" },
];

export const ClientReview = [
  {
    name: "Yagnesh Singh",
    company: "Acquisory",
    description:
      "Working with KonnectSquare has been an incredible experience. Their expertise in recruitment is invaluable. They've helped us find top-notch talent at Acquisory, especially for those challenging roles requiring niche skills. The team is responsive, and they present candidates who are an excellent fit. I look forward to our continued partnership with KonnectSquare!",
  },
  {
    name: "Hiral Udani",
    company: "Edelweiss",
    description:
      "I highly recommend KonnectSquare for their exceptional recruitment services. Over the past year, they've consistently provided us with top-quality candidates. Their industry knowledge and responsiveness are second to none. KonnectSquare is a valuable business partner, and I wholeheartedly endorse them for your recruitment needs.",
  },
  {
    name: "Amit Jain",
    company: "Ambit",
    description:
      "We've had a fruitful partnership with KonnectSquare for the past two years. Their unique talent-based research and sourcing solutions have been crucial for our global roles. As we expand our business lines, we rely on KonnectSquare for quality talent with the right expertise. We look forward to many more years of partnership with KonnectSquare.",
  },
  {
    name: "Namrata Das",
    company: "Eisner Ampner",
    description:
      "Our experience working with KonnectSquare has been outstanding. Their agility and responsiveness in finding high-quality talent for our rapidly growing business is remarkable. Their deep understanding of our business and talent agenda makes them an excellent partner. I highly recommend the KonnectSquare team",
  },
  {
    name: "Nikita Aneja",
    company: "BDO",
    description:
      "KonnectSquare has been an invaluable recruitment partner. They bring competence, positivity, and a friendly approach that is rare in this industry. They offer exceptional guidance throughout the recruitment process and show genuine interest in our progress. I wholeheartedly recommend KonnectSquare for their top-class service.",
  },
  {
    name: "Akanksha",
    company: "Morgan Stanley",
    description:
      "We have had a long-standing relationship with KonnectSquare and couldn't be happier. Their understanding of our business needs, dedication, and responsive teams are exceptional. They cater to various sectors and have been a pleasure to work with. We look forward to nurturing this relationship.",
  },
  {
    name: "Varda Khanzode",
    company: "CRISIL",
    description:
      "Our association with KonnectSquare spans over a decade and a half, and it has been a remarkable journey. Their ability to understand our business and culture and provide the perfect talent fit across diverse requirements sets them apart. KonnectSquare is a valued partner, and we look forward to many more successful collaborations.",
  },
  {
    name: "Preeti Menon",
    company: "KNAV",
    description:
      "KonnectSquare has played a pivotal role in helping us build a strong startup team. Their understanding of our unique needs and the talent marketplace is commendable. They've provided the backbone for our success, and we continue to receive superior value from their services.",
  },
];
